import { authenticationService } from '../_services';
import { history} from "../_helpers";
import { authenticationConstants } from "../_constants";

export const authenticationActions = {
    login,
    logout,
    clearError
};

function login(token) {
    return dispatch => {
        dispatch({ type: authenticationConstants.LOGGING_IN });

        authenticationService.login(token).then(
            data => {
                dispatch({type: authenticationConstants.LOGGED_IN, token: data});

                history.push('/');
            },
            error => {
                dispatch({type: authenticationConstants.ERROR, error: error.message});
            }
        );
    };
}

function logout() {
    return { type: authenticationConstants.LOGOUT };
}

function clearError() {
    return { type: authenticationConstants.CLEAR_ERROR };
}
