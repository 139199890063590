
const prefix = 'REPORTS_';

export const reportsConstants = {
    UPDATE_REPORTS: prefix + 'UPDATE_REPORTS',
    UPDATE_REPORTS_RESPONSE: prefix + 'UPDATE_REPORTS_RESPONSE',
    UPDATE_ASSIGNEE: prefix + 'UPDATE_ASSIGNEE',
    UPDATE_ASSIGNEE_RESPONSE: prefix + 'UPDATE_ASSIGNEE_RESPONSE',
    UPDATE_CHATLOG_RESPONSE: prefix + 'UPDATE_CHATLOG_RESPONSE'
};
