import React from "react";
import { Link } from 'react-router-dom';
import Humanize from 'humanize';
import ReactTimeago from "react-timeago";


class LatestBoosts extends React.Component {

    constructor(props) {
        super(props);
        this.state = { limit: 100 };
    }

    switch = () => { }

    render() {
        const { finances } = this.props;
        const { limit } = this.state;
        const data = finances.latestBoosts;
        const canLoadMore = data && limit < data.length;

        return (
            <div className="card">
                <div className="card-header d-sm-flex align-items-start justify-content-between">
                    <h6 className="lh-5 mg-b-0">Latest Boosts</h6>
                </div>

                <div className="card-body pd-y-15 pd-x-10">
                    <div className="table-responsive table-striped" style={{ maxHeight: 320 }}>
                        <table className="table table-borderless table-sm tx-13 tx-nowrap mg-b-0">
                            <thead>
                                <tr className="tx-10 tx-spacing-1 tx-color-03 tx-uppercase">
                                    <th>Name</th>
                                    <th>Shards</th>
                                    <th>Profit</th>
                                    <th>Time</th>
                                </tr>
                            </thead>

                            <tbody>
                                {data && data.slice(0, Math.min(data.length, limit)).map(c => <tr key={c.time}>
                                    <td><Link to={`/characters/${c.id}`}><b>{c.name}</b></Link></td>
                                    <td>{Humanize.numberFormat(c.total_shards, 0)}</td>
                                    <td>&euro; {(c.total_shards / 86.0).toFixed()}</td>
                                    <td><ReactTimeago date={c.time * 1000} /></td>
                                </tr>)}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        );
    }

}

export default LatestBoosts;
