import React from 'react';
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { Plus } from "react-feather";
import { apiServices } from "./_services";
import Countdown from 'react-countdown-now';

class AssetManagement extends React.Component {


    constructor(props) {
        super(props);

        this.state = {
            assetFormRef: React.createRef(),
            androidRef: React.createRef(),
            iOSRef: React.createRef(),
            timerRef: React.createRef(),
            uploading: false,
            filename: null,
            audioAndroid: null,
            audioiOS: null,
            qa: true,
            fired: false,
            seconds: 300,
            shutdownDate: null,
            shutdownWorld: ''
        };
    }

    submitRegularForm = (e) => {
        e.preventDefault();
        this.setState({ filename: null, uploading: true });

        const formData = new FormData();
        formData.append("file", this.state.assetFormRef.current.files[0]);

        apiServices.axios.post(this.state.qa ? '/assets/upload-qa' : '/assets/upload', formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        }).then(r => {
            this.setState({ filename: r.data, uploading: false });
        });

        return false;
    }

    submitAudioForm = (e) => {
        e.preventDefault();
        this.setState({ audioAndroid: null, audioiOS: null, uploading: true });

        const formData = new FormData();
        formData.append("audio-android", this.state.androidRef.current.files[0]);
        formData.append("audio-ios", this.state.iOSRef.current.files[0]);

        apiServices.axios.post('/assets/upload-audio', formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        }).then(r => {
            console.log(r.data);
            this.setState({ audioResp: r.data, uploading: false });
        });

        return false;
    }

    submitTimers = (e) => {
        e.preventDefault();

        const seconds = this.state.seconds;
        const world = this.state.shutdownWorld|0;
        const params = world > 0 ? ({seconds, world}) : ({seconds});

        apiServices.axios.post('/shutdown', params).then(r => {
            this.setState({ fired: true, shutdownDate: new Date(Date.now() + (seconds * 1000)) });
            window.setTimeout(() => this.notify('Servers are offline!'), seconds * 1000);
            this.getPermissions();
        });

        return false;
    }

    getPermissions() {
        if (Notification.permission === "default") {
            Notification.requestPermission().then((permission) => {
                console.log("Perms: " + permission);
            });
        }
    }

    notify(message) {
        if (!("Notification" in window)) {
            alert(message);
        } else if (Notification.permission === "granted") {
            new Notification(message);
        } else if (Notification.permission !== "denied") {
            Notification.requestPermission().then((permission) => {
                if (permission === "granted") {
                    new Notification(message);
                } else {
                    alert(message);
                }
            });
        }
    }

    render() {
        const { dispatch } = this.props;
        const { config, assetFormRef, androidRef, iOSRef, uploading, filename, qa, audioResp,
            timerRef, fired, shutdownDate, seconds, shutdownWorld } = this.state;

        const timerEnd = new Date(seconds * 1000);

        return (
            <div className="container-fluid pd-x-0 tx-13">
                <div className="d-sm-flex align-items-center justify-content-between mg-b-20 mg-lg-b-30">
                    <div>
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb breadcrumb-style1 mg-b-10">
                                <li className="breadcrumb-item"><Link to="/">Dashboard</Link></li>
                                <li className="breadcrumb-item active" aria-current="page">Game Assets</li>
                            </ol>
                        </nav>

                        <h4 className="mg-b-0 tx-spacing--1">Game Assets</h4>
                        <p className="tx-color-03 mg-b-25">Upload new game assets.</p>
                    </div>

                    <div className="d-none d-md-block">
                        <button className="btn btn-sm pd-x-15 btn-primary btn-uppercase mg-l-5"><Plus /> Refresh</button>
                    </div>
                </div>

                <div className="row">
                    <div className="col-sm-4">
                        <div className="card">
                            <div className="card-header d-sm-flex align-items-start justify-content-between">
                                <h6 className="lh-5 mg-b-0">Game Assets</h6>
                            </div>

                            <div className="card-body">
                                <form onSubmit={this.submitRegularForm}>
                                    <label>Asset:</label><br />
                                    <input ref={assetFormRef} type="file" name="file"></input><br />
                                    <input type="checkbox" checked={qa} onChange={e => this.setState({ qa: e.target.checked })}></input>Upload to QA<br />
                                    <button type="submit" disabled={uploading}>{uploading ? 'Uploading...' : 'Upload'}</button>
                                    {filename ? <span>Filename: <code>{filename}</code></span> : null}
                                </form>
                            </div>
                        </div>
                    </div>

                    <div className="col-sm-4">
                        <div className="card">
                            <div className="card-header d-sm-flex align-items-start justify-content-between">
                                <h6 className="lh-5 mg-b-0">Audio Files</h6>
                            </div>

                            <div className="card-body">
                                <form onSubmit={this.submitAudioForm}>
                                    <label>Android:</label><br />
                                    <input ref={androidRef} type="file" name="file"></input><br /><br />
                                    <label>iOS:</label><br />
                                    <input ref={iOSRef} type="file" name="file"></input><br />
                                    <button type="submit" disabled={uploading}>{uploading ? 'Uploading...' : 'Upload'}</button>
                                    {audioResp ? <span><code>{JSON.stringify(audioResp)}</code></span> : null}
                                </form>
                            </div>
                        </div>
                    </div>

                    <div className="col-sm-4">
                        <div className="card">
                            <div className="card-header d-sm-flex align-items-start justify-content-between">
                                <h6 className="lh-5 mg-b-0">Game Shutdown</h6>
                            </div>

                            <div className="card-body">
                                <p>Nifty little form that lets you yeet it all offline.</p>

                                <form onSubmit={this.submitTimers}>
                                    <label>Timer (in seconds):</label><br />
                                    (300: 5 minutes, 600: 10 minutes, 900: 15 minutes, 1800: 30 minutes, 3600: 1 hour)<br />
                                    <input value={seconds} onChange={e => this.setState({ seconds: Number(e.target.value) })} type="number" name="timer"></input> <br /><br />

                                    <br/>
                                    
                                    <label>World ID (optional):</label><br />
                                    <input value={shutdownWorld} onChange={e => this.setState({ shutdownWorld: e.target.value.length ? Number(e.target.value) : null })} type="text" name="timer" placeholder="Leave blank for all worlds."></input> <br /><br />

                                    <button type="submit" className="btn btn-danger" disabled={fired}>{fired ? 'Fired.' : <>Fire timer for <b>{('0' + timerEnd.getUTCHours()).slice(-2) + ":" + ('0' + timerEnd.getUTCMinutes()).slice(-2) + ":" + ('0' + timerEnd.getUTCSeconds()).slice(-2)}</b></>}</button><br />
                                    {fired ? <>Timer: {shutdownDate.getTime() <= Date.now() ? 'DOWN!' : <Countdown date={shutdownDate} daysInHours />}</> : null}
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

}


function mapStateToProps(state) {
    return {};
}

const connected = connect(mapStateToProps)(AssetManagement);
export { connected as AssetManagement };
