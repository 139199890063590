import React from "react";
import { Bar } from 'react-chartjs-2';

class NewUserChart extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            chartKey: 'newPlayers24h'
        };

        this.switch = this.switch.bind(this);
    }

    switch(key) {
        this.setState({ chartKey: key });
    }

    render() {
        const graph = this.props.data[this.state.chartKey] || [];

        let labels = [];
        let data = [];

        graph.forEach(a => {
            labels.push(a.date);
            data.push(a.count.toFixed());
        });

        console.log(graph);

        const chartData = {
            labels: labels,
            datasets: [
                {
                    label: 'Registrations',
                    backgroundColor: 'rgba(1, 104, 250, 0.2)',
                    borderColor: 'rgba(1, 104, 250)',
                    borderWidth: 1,
                    hoverBackgroundColor: 'rgba(1, 104, 250, 0.4)',
                    hoverBorderColor: 'rgba(1, 104, 250, 1)',
                    data: data
                }
            ]
        };

        return (
            <div className="card">
                <div className="card-header d-sm-flex align-items-start justify-content-between">
                    <h6 className="lh-5 mg-b-0 flex-1">New Users</h6>

                    <select value={this.state.chartKey} className="form-control form-control-sm flex-1 finance-selector" onChange={(e) => this.switch(e.target.value)}>
                        <option value="newPlayers1h">Last hour (per min)</option>
                        <option value="newPlayers6h">Last 6 hours (per min)</option>
                        <option value="newPlayers24h">Last 24 hours</option>
                        <option value="newPlayersWeek">Last week</option>
                        <option value="newPlayersMonthly">Last 31 days</option>
                        <option value="newPlayers180d">Last 180 days</option>
                    </select>
                </div>

                <div className="card-body pd-y-15 pd-x-10">
                    <Bar data={chartData} options={{
                        hover: { intersect: false }, scales: {
                            yAxes: [{
                                stacked: true
                            }],
                            xAxes: [{ stacked: true }]
                        }
                    }} />
                </div>
            </div>
        );
    }

}

export default NewUserChart;
