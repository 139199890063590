import React from "react";
import { connect } from 'react-redux';
import { RefreshCw, BarChart2, Maximize2 } from "react-feather";
import Humanize from 'humanize';

const KeyStatistic = ({ title, children }) => (
    <div className="col-sm-6 col-lg-2">
        <div className="media">
            <div className="wd-40 wd-md-50 ht-40 ht-md-50 bg-teal tx-white mg-r-10 mg-md-r-10 d-flex align-items-center justify-content-center rounded op-6">
                <BarChart2 />
            </div>

            <div className="media-body">
                <h6 className="tx-sans tx-uppercase tx-10 tx-spacing-1 tx-color-03 tx-semibold tx-nowrap mg-b-5 mg-md-b-8">{title}</h6>
                <h4 className="tx-20 tx-sm-18 tx-md-24 tx-normal tx-rubik mg-b-0">{children}</h4>
            </div>
        </div>
    </div>
);

const PageHeader = ({ finances, account, wide, setWide }) => {
    const now = new Date();
    const firstDay = new Date(now.getFullYear(), now.getMonth(), 1);
    const lastDay = new Date(now.getFullYear(), now.getMonth() + 1, 0);
    const hoursThisMonth = (lastDay.getTime() - firstDay.getTime()) / 3600000;
    const elapsedHours = ((new Date().getTime() - firstDay.getTime()) / 3600000) || 1;

    return (
        <>
            <div className="d-sm-flex align-items-center justify-content-between mg-b-20 mg-lg-b-30">
                <div>
                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb breadcrumb-style1 mg-b-10">
                            <li className="breadcrumb-item"><a href="dashboard-four.html#">Dashboard</a></li>
                            <li className="breadcrumb-item active" aria-current="page">Overview</li>
                        </ol>
                    </nav>
                    <h4 className="mg-b-0 tx-spacing--1">Welcome to your money, {account.username}.</h4>
                </div>

                <div className="d-none d-md-block">
                    <button className="btn btn-sm pd-x-15 btn-white btn-uppercase"><RefreshCw className="wd-10 mg-r-5" /> Refresh</button>
                    <button className={`btn btn-sm pd-x-15 btn-uppercase mg-l-5 ${wide ? "btn-primary" : "btn-outline-primary"}`} onClick={() => setWide(!wide)}><Maximize2 className="wd-10 mg-r-5" /> Wide-screen</button>
                </div>
            </div>

            <div className="row mb-4">
                <KeyStatistic title="Revenue Today">
                    &euro;{finances.financesToday && Humanize.numberFormat(finances.financesToday.revenue, 0)}
                </KeyStatistic>

                <KeyStatistic title="Rev. Yesterday">
                    &euro;{finances.financesYesterday && Humanize.numberFormat(finances.financesYesterday.revenue, 0)}
                </KeyStatistic>

                <KeyStatistic title="Month-to-date">
                    &euro;{finances.sumMTD && Humanize.numberFormat(finances.sumMTD.revenue, 0)}
                </KeyStatistic>

                <KeyStatistic title="Last month">
                    &euro;{finances.sumLastMonth && Humanize.numberFormat(finances.sumLastMonth.revenue, 0)}
                </KeyStatistic>

                <KeyStatistic title="Prediction MTD">
                    &euro;{finances.sumMTD && Humanize.numberFormat((finances.sumMTD.revenue / elapsedHours * hoursThisMonth).toFixed(), 0)}
                </KeyStatistic>

                <KeyStatistic title={ (finances.numSubscribers ? Humanize.numberFormat(finances.numSubscribers, 0) : 0) + " Members"}>
                    &euro;{Humanize.numberFormat(finances.numSubscribers*8.4575, 0)}/mo
                </KeyStatistic>

                {/*<KeyStatistic title="Month's Potential">
                    &euro;{finances.sumMTD && Humanize.numberFormat((finances.sumMTD.revenue / elapsedHours * hoursThisMonth * 6.1).toFixed(), 0)}
                </KeyStatistic>*/}
            </div>
        </>
    );
}

export default connect(({ finances, authentication }) => ({ finances, account: authentication.account }))(PageHeader);
