import { combineReducers } from 'redux';
import {authentication} from "./authentication.reducer";
import {finances} from "./financial.reducer";
import {statistics} from "./statistics.reducer";
import {staff} from "./staff.reducer";
import {nav} from "./nav.reducer";
import {reports} from "./reports.reducer";

const rootReducer = combineReducers({
    authentication,
    finances,
    statistics,
    staff,
    nav,
    reports
});

export default rootReducer;
