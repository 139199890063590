
import React, { useState } from "react";
import { connect } from "react-redux";
import { apiServices } from "./_services";
import User from 'react-feather/dist/icons/user';
import MessageSquare from 'react-feather/dist/icons/message-square';
import { Link } from "react-router-dom";
import { Router, Route, Switch } from "react-router";
import { RefreshCw, Plus, List, Delete, Clock, Trash, Key, Crosshair, Edit, Briefcase, VolumeX, CreditCard, ShoppingBag, ShoppingCart, Package, Award } from "react-feather";
import { store } from "./_helpers/store";
import { xpToLevel } from "./_helpers";
import dataServices from "./_services/data.services";
import { numberFormat } from "humanize";
import { OnlineBadge } from "./OnlineBadge";
import { Report } from "./Report";
import ReactTimeago from "react-timeago";

import guardianImg from './css/images/ranks/guardian.png';

class CharacterView extends React.Component {

    constructor(props) {
        super(props);

        const { character: characterId } = this.props.match.params;

        this.state = {
            characterId,
            character: null,
            chatlogs: [],
            logins: [],
            notes: [],
            nameChanges: [],
            purchases: [],
            punishments: [],
            cosmetics: [],
            shards: [],
            trades: [],
            rareDrops: [],
            reports: [],
            shardInput: undefined,
            lampsInput: undefined
        };

        this.refresh = this.refresh.bind(this);
        this.giveShards = this.giveShards.bind(this);
        this.giveLamps = this.giveLamps.bind(this);

        // Fetch character info
        this.refresh();
    }

    giveShards() {
        apiServices.axios.post(`/characters/${this.state.characterId}/shards/add`, { shards: this.state.shardInput | 0 });
    }

    giveLamps() {
        apiServices.axios.post(`/characters/${this.state.characterId}/lamps/add`, { lamps: this.state.lampsInput | 0 });
    }

    componentWillReceiveProps(nextProps) {
        const { character } = nextProps.match.params;

        if (character && character != this.state.characterId) {
            this.setState({ characterId: character }, () => {
                this.refresh();
            });
        }
    }

    refresh() {
        apiServices.axios.get('/characters/' + this.state.characterId).then(data => {
            let character = data.data;

            // Map inventories to actual objects
            character.inventory = JSON.parse(character.inventory);
            character.equipment = JSON.parse(character.equipment);
            character.bank = JSON.parse(character.bank);

            this.setState({ character });
        });

        apiServices.axios.get('/characters/' + this.state.characterId + '/chatlog.json').then(data => this.setState({ chatlogs: data.data }));
        apiServices.axios.get('/characters/' + this.state.characterId + '/logins.json').then(data => this.setState({ logins: data.data }));
        apiServices.axios.get('/characters/' + this.state.characterId + '/notes.json').then(data => this.setState({ notes: data.data }));
        apiServices.axios.get('/characters/' + this.state.characterId + '/punishments.json').then(data => this.setState({ punishments: data.data }));
        apiServices.axios.get('/characters/' + this.state.characterId + '/names.json').then(data => this.setState({ nameChanges: data.data }));
        apiServices.axios.get('/characters/' + this.state.characterId + '/purchases.json').then(data => this.setState({ purchases: data.data }));
        apiServices.axios.get('/characters/' + this.state.characterId + '/cosmetics-purchases.json').then(data => this.setState({ cosmetics: data.data }));
        apiServices.axios.get('/characters/' + this.state.characterId + '/shards.json').then(data => this.setState({ shards: data.data }));
        apiServices.axios.get('/characters/' + this.state.characterId + '/trades.json').then(data => this.setState({ trades: data.data }));
        apiServices.axios.get('/characters/' + this.state.characterId + '/drops.json').then(data => this.setState({ rareDrops: data.data }));
        apiServices.axios.get('/characters/' + this.state.characterId + '/reports.json').then(data => this.setState({ reports: data.data }));
        apiServices.axios.get('/characters/' + this.state.characterId + '/membership.json').then(data => this.setState({ membership: data.data }));
    }

    render() {
        const { characterId, character, chatlogs, logins, notes, shardInput, lampsInput, punishments,
            nameChanges, purchases, cosmetics, shards, trades, rareDrops, reports } = this.state;
        const tileX = character ? (character.x / 16).toFixed(0) : 0;
        const tileY = character ? (character.y / 16).toFixed(0) : 0;

        let online = store.getState().statistics.players.find(pl => pl.id == characterId);

        if (!character) {
            return <p>Loading character data...</p>;
        }

        const staff_account_id = store.getState().authentication.account.account_id;

        return (
            <div className="container pd-x-0 tx-13">
                <div className="d-sm-flex align-items-center justify-content-between mg-b-20 mg-lg-b-30">
                    <div>
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb breadcrumb-style1 mg-b-10">
                                <li className="breadcrumb-item"><Link to="/">Dashboard</Link></li>
                                <li className="breadcrumb-item active" aria-current="page">{character.name}</li>
                            </ol>
                        </nav>
                        <h4 className="mg-b-0 tx-spacing--1">Character management</h4>
                    </div>
                    <div className="d-none d-md-block">
                        <button onClick={() => this.refresh()} className="btn btn-sm pd-x-15 btn-white btn-uppercase"><RefreshCw /> Refresh</button>
                        <button className="btn btn-sm pd-x-15 btn-primary btn-uppercase mg-l-5"><Plus /> View account</button>
                    </div>
                </div>

                <div className="row">
                    <div className="col-sm-3 col-md-3 card">
                        <div className="row">
                            <div className="card-body">
                                <h5 className="mg-b-2 tx-spacing--1" style={{ color: character.name_color }}>{character.name} <OnlineBadge id={character.id} /> <span className="text-muted" style={{ fontSize: 11 }}>#{character.id}</span>
                                    {character.banned && <span className="badge badge-danger ml-2">Banned</span>}
                                    {character.muted && <span className="badge badge-warning ml-2">Muted</span>}
                                    {character.staff && <span className="badge badge-info ml-2">Staff</span>}
                                </h5>
                                <p className="tx-color-03 mg-b-25">{character.email}</p>

                                <table>
                                    <tbody>
                                        <tr><td>Registered:</td><td><span className="text-muted">{character.created_at}</span></td></tr>
                                        <tr><td>Last&nbsp;online:</td><td><span className="text-muted">{character.last_online}</span></td></tr>
                                        <tr><td>Playtime:</td><td><span className="text-muted">{character.playtime.replace("0 years 0 mons 0 days ", "")}</span></td></tr>
                                        <tr><td>Name&nbsp;color:</td><td><span className="" style={{ color: character.name_color ? ('#' + character.name_color) : '#7987a1' }}>{character.name_color || 'None'}</span></td></tr>

                                        <tr><td>Account:</td><td><span className="text-muted">#{character.account_id}</span></td></tr>
                                    </tbody>
                                </table>

                                <hr />

                                <p>
                                    Level: {xpToLevel(character.xp)}<br />
                                    XP: {numberFormat(character.xp, 0)}<br />
                                    Gold: {numberFormat(character.gold, 0)}<br />
                                    Bank: {numberFormat(character.bank_gold, 0)}<br />
                                    Shards: {numberFormat(character.shards, 0)}<br />
                                    Lamps: {numberFormat(character.lamps, 0)}<br />
                                    Tile: {tileX}, {tileY}<br />
                                </p>

                                <div className="d-flex mg-b-25">
                                    <Link to={`/characters/${characterId}/punish`} className="btn btn-xs flex-fill btn-outline-danger">Punish Player</Link>
                                </div>

                                <div className="d-flex mg-b-25">
                                    <button className="btn btn-xs btn-white flex-fill" onClick={() => apiServices.axios.post(`/accounts/${character.account_id}/color.json`, { color: 'ffa500' })}>Make Patreon</button>
                                    <button className="btn btn-xs btn-white flex-fill" onClick={() => apiServices.axios.post(`/accounts/${character.account_id}/color.json`, { color: '6ae2e0' })}>Make Cursed</button>
                                </div>

                                {staff_account_id === 150 || staff_account_id === 1790670 ? <div className="d-flex mg-b-25">
                                    <button className="btn btn-xs btn-white flex-fill" onClick={() => apiServices.axios.post(`/accounts/${character.account_id}/guardian/promote`, { character_id: characterId|0 })}><img src={guardianImg} width={24} height={24} /> Promote</button>
                                    <button className="btn btn-xs btn-white flex-fill" onClick={() => apiServices.axios.post(`/accounts/${character.account_id}/guardian/demote`, { character_id: characterId|0 })}><img src={guardianImg} width={24} height={24} /> Demote</button>
                                </div> : null}

                                <div className="d-flex mg-b-25">
                                    <button className="btn btn-xs btn-white flex-fill" onClick={() => { window.confirm(`Are you sure you want to kick ${character.name}?`) && apiServices.axios.post(`/characters/${characterId}/kick`) }}>Kick</button>
                                    <button className="btn btn-xs btn-white flex-fill" onClick={() => apiServices.axios.post(`/characters/${characterId}/teleport`, { tile_x: 276, tile_y: 291 })}>Teleport Home</button>
                                </div>

                                <hr />

                                <div className="d-flex mg-b-20">
                                    <div className="input-group">
                                        <input type="text" className="form-control" placeholder="Shards" onChange={(e) => this.setState({ shardInput: e.target.value })} value={shardInput} />
                                        <div className="input-group-append">
                                            <button className="btn btn-outline-light" type="button" id="button-addon2" onClick={this.giveShards}>Give Shards</button>
                                        </div>
                                    </div>
                                </div>

                                <div className="d-flex mg-b-20">
                                    <div className="input-group">
                                        <input type="text" className="form-control" placeholder="Lamps" onChange={(e) => this.setState({ lampsInput: e.target.value })} value={lampsInput} />
                                        <div className="input-group-append">
                                            <button className="btn btn-outline-light" type="button" onClick={this.giveLamps}>Give Lamps</button>
                                        </div>
                                    </div>
                                </div>

                                <hr />

                                <label className="tx-sans tx-10 tx-semibold tx-uppercase tx-color-01 tx-spacing-1 mg-b-15">Navigation</label>
                                <nav className="nav nav-classic tx-13">
                                    <Link to={`/characters/${characterId}`} className="nav-link"><User /> Summary</Link>
                                    <Link to={`/characters/${characterId}/logs/chat`} className="nav-link"><MessageSquare /> Chat logs</Link>
                                    <Link to={`/characters/${characterId}/logs/logins`} className="nav-link"><Key /> Logins</Link>
                                    <Link to={`/characters/${characterId}/inventory`} className="nav-link"><Briefcase /> Inventory</Link>
                                    <Link to={`/characters/${characterId}/notes`} className="nav-link"><Edit /> Notes <span className="badge badge-primary">{notes.length}</span></Link>
                                    <Link to={`/characters/${characterId}/punishments`} className="nav-link"><Crosshair /> Punishments <span className="badge badge-primary">{punishments.length}</span></Link>
                                    <Link to={`/characters/${characterId}/names`} className="nav-link"><List /> Name history <span className="badge badge-primary">{nameChanges.length}</span></Link>
                                    <Link to={`/characters/${characterId}/purchases`} className="nav-link"><CreditCard /> Purchases <span className="badge badge-primary">{purchases.length}</span></Link>
                                    <Link to={`/characters/${characterId}/cosmetics`} className="nav-link"><ShoppingCart /> Cosmetics <span className="badge badge-primary">{cosmetics.length}</span></Link>
                                    <Link to={`/characters/${characterId}/shards`} className="nav-link"><ShoppingBag /> Shard Purchases <span className="badge badge-primary">{shards.length}</span></Link>
                                    <Link to={`/characters/${characterId}/trades`} className="nav-link"><Package /> Trades <span className="badge badge-primary">{trades.length}</span></Link>
                                    <Link to={`/characters/${characterId}/drops`} className="nav-link"><Award /> Rare Drops <span className="badge badge-primary">{rareDrops.length}</span></Link>
                                    <Link to={`/characters/${characterId}/reports`} className="nav-link"><Award /> Reports <span className="badge badge-primary">{reports.length}</span></Link>
                                </nav>
                            </div>
                        </div>
                    </div>

                    <div className="col-sm-9 col-md-9">
                        <Switch>
                            <Route exact path="/characters/:character" component={() => <p>This is home</p>} />
                            <Route exact path="/characters/:character/logs/chat" component={() => <Chatlogs logs={chatlogs} />} />
                            <Route exact path="/characters/:character/logs/logins" component={() => <Logins logins={logins} />} />
                            <Route exact path="/characters/:character/inventory" component={() => <Inventories character={character} />} />
                            <Route exact path="/characters/:character/notes" component={() => <Notes notes={notes} />} />
                            <Route exact path="/characters/:character/punishments" component={() => <Punishments punishments={punishments} />} />
                            <Route exact path="/characters/:character/names" component={() => <NameChanges nameChanges={nameChanges} />} />
                            <Route exact path="/characters/:character/punish" component={() => <Punishing character={character} punishments={punishments} refresh={this.refresh} />} />
                            <Route exact path="/characters/:character/purchases" component={() => <Purchases character={character} purchases={purchases} />} />
                            <Route exact path="/characters/:character/cosmetics" component={() => <Cosmetics character={character} cosmetics={cosmetics} />} />
                            <Route exact path="/characters/:character/shards" component={() => <ShardPurchases character={character} shards={shards} />} />
                            <Route exact path="/characters/:character/trades" component={() => <TradeLogs character={character} trades={trades} />} />
                            <Route exact path="/characters/:character/drops" component={() => <RareDrops character={character} drops={rareDrops} />} />
                            <Route exact path="/characters/:character/reports" component={() => <Reports character={character} reports={reports} />} />
                        </Switch>
                    </div>
                </div>
            </div>
        );
    }

}

class Punishing extends React.Component {

    render() {
        const { character, punishments, refresh } = this.props;

        return (
            <div>
                <div className="row">
                    <div className="col-md-6">
                        <div className="card">
                            <div className="card-header d-sm-flex align-items-start justify-content-between">
                                <h6 className="lh-5 mg-b-0"><VolumeX /> Mute / Unmute</h6>
                            </div>

                            <div className="card-body pd-y-15 pd-x-10">
                                {character.muted ? <this.UnmuteCard character={character} refresh={refresh} /> : <this.MuteCard character={character} refresh={refresh} />}
                            </div>
                        </div>
                    </div>

                    <div className="col-md-6">
                        <div className="card">
                            <div className="card-header d-sm-flex align-items-start justify-content-between">
                                <h6 className="lh-5 mg-b-0"><Crosshair /> Ban / Unban</h6>
                            </div>

                            <div className="card-body pd-y-15 pd-x-10">
                                {character.banned ? <UnbanCard character={character} refresh={refresh} /> : <this.BanCard character={character} refresh={refresh} />}
                            </div>
                        </div>
                    </div>
                </div>

                <hr />

                <div className="row">
                    <div className="col-md-12">
                        <Punishments punishments={punishments} />
                    </div>
                </div>
            </div>
        );
    }

    UnmuteCard(props) {
        const [unmutereason, setUnmuteReason] = useState('');
        return (
            <div>
                This player is currently muted until <b>{props.character.muted_until}</b>. You can unmute them below.

                <hr />

                <form onSubmit={(e) => {
                    const reason = document.getElementById("unmutereason").value;
                    apiServices.axios.post(`/accounts/${props.character.account_id}/unmute`, {
                        character_id: props.character.id,
                        reason
                    }).then(() => props.refresh());
                    e.preventDefault();
                    return false;
                }}>
                    <div className="form-group">
                        <label htmlFor="formGroupExampleInput" className="d-block">Reason</label>
                        <input type="text" className="form-control"
                            placeholder="Enter unmute reason to aid staff members." id="unmutereason"
                            value={unmutereason}
                            onChange={(e) => setUnmuteReason(e.target.value)} />
                    </div>

                    <button className="btn btn-primary">Unmute {props.character.name}</button>
                </form>
            </div>
        );
    }

    MuteCard(props) {
        return (
            <div>
                This player is currently not (shadow)muted. If they have violated the game rules, you can mute them below.

                <hr />

                <form onSubmit={(e) => {
                    const reason = document.getElementById("mutereason").value;
                    const duration = document.getElementById("mutelength").value;
                    const shadow = document.getElementById("shadowmute").checked;
                    apiServices.axios.post(`/accounts/${props.character.account_id}/mute`, { character_id: props.character.id, reason, duration, shadow }).then(() => props.refresh()); e.preventDefault(); return false;
                }}>
                    <div className="form-group">
                        <label htmlFor="formGroupExampleInput" className="d-block">Reason</label>
                        <input type="text" className="form-control" placeholder="Enter mute reason to aid staff members." id="mutereason" />
                    </div>

                    <div className="form-group">
                        <label htmlFor="formGroupExampleInput2" className="d-block">Duration</label>
                        <select className="custom-select" id="mutelength">
                            <option value="24 hours">24 hours</option>
                            <option value="7 days">7 days</option>
                            <option value="1 month">1 month</option>
                            <option value="1 year">1 year</option>
                            <option value="10 years">Permanently</option>
                        </select>
                    </div>

                    <input type="checkbox" id="shadowmute" /> Shadowmute

                    <button className="btn btn-warning ml-4">Mute {props.character.name}</button>
                </form>
            </div>
        );
    }

    BanCard(props) {
        return (
            <div>
                This player is currently not banned. If they have violated the game rules, you can ban them below.

                <hr />

                <form onSubmit={(e) => {
                    const reason = document.getElementById("banreason").value;
                    const duration = document.getElementById("banlength").value;
                    apiServices.axios.post(`/accounts/${props.character.account_id}/ban`, { character_id: props.character.id, reason, duration }).then(() => props.refresh()); e.preventDefault(); return false;
                }}>
                    <div className="form-group">
                        <label htmlFor="formGroupExampleInput" className="d-block">Reason</label>
                        <input type="text" className="form-control" placeholder="Enter ban reason to aid staff members." id="banreason" />
                    </div>

                    <div className="form-group">
                        <label htmlFor="formGroupExampleInput2" className="d-block">Duration</label>
                        <select className="custom-select" id="banlength">
                            <option value="24 hours">24 hours</option>
                            <option value="7 days">7 days</option>
                            <option value="1 month">1 month</option>
                            <option value="1 year">1 year</option>
                            <option value="10 years">Permanently</option>
                        </select>
                    </div>

                    <button className="btn btn-danger">Ban {props.character.name}</button>
                </form>
            </div>
        );
    }

}

class UnbanCard extends React.Component {
    constructor(props) {
        super(props);

        this.state = { unbanreason: '' };
    }
    render() {
        const props = this.props;
        const state = this.state;
        return (
            <div>
                This player is currently banned until <b>{props.character.banned_until}</b>. You can unban them below.

                <hr />

                <form onSubmit={(e) => {
                    const reason = document.getElementById("unbanreason").value;
                    apiServices.axios.post(`/accounts/${props.character.account_id}/unban`, {
                        character_id: props.character.id,
                        reason
                    }).then(() => props.refresh());
                    e.preventDefault();
                    return false;
                }}>
                    <div className="form-group">
                        <label htmlFor="formGroupExampleInput" className="d-block">Reason</label>
                        <input type="text" className="form-control"
                            placeholder="Enter unban reason to aid staff members." id="unbanreason"
                            value={state.unbanreason}
                            onChange={(e) => this.setState({ unbanreason: e.target.value })} />
                    </div>

                    <button className="btn btn-danger">Unban {props.character.name}</button>
                </form>
            </div>
        );
    }
}

class Chatlogs extends React.Component {

    render() {
        const { logs } = this.props;

        return (
            <div className="card">
                <div className="card-header d-sm-flex align-items-start justify-content-between">
                    <h6 className="lh-5 mg-b-0">Chat logs</h6>
                    <a href="dashboard-two.html" className="tx-13 link-03">Last 7 days <i className="icon ion-ios-arrow-down"></i></a>
                </div>

                <div className="card-body pd-y-15 pd-x-10">
                    <div className="table-responsive table-striped" style={{ maxHeight: 1080 }}>
                        <table className="table table-borderless table-sm tx-13 tx-nowrap mg-b-0">
                            <thead>
                                <tr className="tx-10 tx-spacing-1 tx-color-03 tx-uppercase">
                                    <th>Time</th>
                                    <th>Message</th>
                                </tr>
                            </thead>
                            <tbody>
                                {logs.map(log =>
                                    <tr key={log.id}>
                                        <td>{log.time}</td>
                                        <td>{log.message}</td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        );
    }

}

class TradeRow extends React.Component {
    state = {
        expanded: false
    }

    constructor(props) {
        super(props);

        this.state = {
            expanded: false,
            tradeInfo: null,
        };

        this.loadTrade = this.loadTrade.bind(this);
        this.renderTrade = this.renderTrade.bind(this);
    }

    toggleExpander = (e, id) => {
        if (e.target.type === 'checkbox') return;

        if (!this.state.expanded) {
            if (!this.state.tradeInfo) {
                this.loadTrade(id);
            }
            this.setState({ expanded: true });
        } else {
            this.setState({ expanded: false });
        }
    }

    loadTrade(id) {
        this.setState({ tradeInfo: null });

        apiServices.axios.get('/trades/' + id).then(data => {
            console.error("Loaded");
            this.setState(
                {
                    tradeInfo: data.data,
                    expanded: true,
                });
        });
    }

    renderTrade() {
        const { tradeInfo: { trade, items } } = this.state;

        const traderItems = items.filter(i => i.owner === trade.trader_id);
        const tradeeItems = items.filter(i => i.owner === trade.tradee_id);

        const itemData = dataServices.getItems();

        return (
            <div className="row">
                <div className="col-md-6">
                    <div className="card">
                        <div className="card-header">
                            <h6>Offered by {trade.trader_name}</h6>
                            <p>{numberFormat(trade.trader_gold, 0)} gold.</p>
                        </div>

                        <div className="card-body pd-y-15 pd-x-10">
                            <table className="table table-borderless table-striped table-sm tx-13 tx-nowrap">
                                <thead>
                                    <tr className="tx-10 tx-spacing-1 tx-color-03 tx-uppercase">
                                        <th>Item</th>
                                        <th>Amount</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {traderItems.map(item =>
                                        <tr key={item.id}>
                                            <td>{itemData[item.type].name}</td>
                                            <td>{numberFormat(item.amount, 0)}</td>
                                        </tr>
                                    )}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>

                <div className="col-md-6">
                    <div className="card">
                        <div className="card-header">
                            <h6>Offered by {trade.tradee_name}</h6>
                            <p>{numberFormat(trade.tradee_gold, 0)} gold.</p>
                        </div>

                        <div className="card-body pd-y-15 pd-x-10">
                            <table className="table table-borderless table-striped table-sm tx-13 tx-nowrap">
                                <thead>
                                    <tr className="tx-10 tx-spacing-1 tx-color-03 tx-uppercase">
                                        <th>Item</th>
                                        <th>Amount</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {tradeeItems.map(item =>
                                        <tr key={item.id}>
                                            <td>{itemData[item.type] ? itemData[item.type].name : `item_${item.type}`}</td>
                                            <td>{numberFormat(item.amount, 0)}</td>
                                        </tr>
                                    )}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div >
        );
    }

    render() {
        const { log, character, index } = this.props;
        const { tradeInfo } = this.state;

        return [
            <tr key={log.id} onClick={(e) => { this.toggleExpander(e, log.id); }}
                className={`${index % 2 == 0 ? "trade-row" : "trade-row-alt"}`}>
                <td>{log.time}</td>
                <td><Link to={`/characters/${log.trader_id === character.id ? log.tradee_id : log.trader_id}`}>{log.trader_id === character.id ? log.tradee_name : log.trader_name}</Link></td>
                <td>{log.item_count}</td>
                <td>{numberFormat(log.trader_id === character.id ? log.trader_gold : log.tradee_gold, 0)}</td>
                <td>{numberFormat(log.trader_id === character.id ? log.tradee_gold : log.trader_gold, 0)}</td>
            </tr>,
            this.state.expanded && (
                <tr className={`${index % 2 == 0 ? "trade-row" : "trade-row-alt"}`}
                    key="tr-expander">
                    <td colSpan={12}>
                        <div>
                            <h6>Trade Contents</h6>
                            {tradeInfo ? this.renderTrade() : <p>Loading...</p>}
                        </div>
                    </td>
                </tr>
            )
        ];
    }
}

class TradeLogs extends React.Component {

    constructor(props) {
        super(props);
    }

    render() {
        const { trades, character } = this.props;

        return (
            <div className="card">
                <div className="card-header d-sm-flex align-items-start justify-content-between">
                    <h6 className="lh-5 mg-b-0">Trade logs</h6>
                    <a href="dashboard-two.html" className="tx-13 link-03">Last 7 days <i className="icon ion-ios-arrow-down"></i></a>
                </div>

                <div className="card-body pd-y-15 pd-x-10">
                    <div className="table-responsive table-striped" style={{ maxHeight: 1080 }}>
                        <table className="table table-borderless table-sm tx-13 tx-nowrap mg-b-0 trade-table">
                            <thead>
                                <tr className="tx-10 tx-spacing-1 tx-color-03 tx-uppercase">
                                    <th>Time</th>
                                    <th>Partner</th>
                                    <th># Items</th>
                                    <th>Gold Offered</th>
                                    <th>Gold Received</th>
                                </tr>
                            </thead>
                            <tbody>
                                {trades.map((log, index) =>
                                    <TradeRow key={index} index={index + 1} log={log} character={character} />
                                )}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        );
    }
}

class Punishments extends React.Component {

    render() {
        const { punishments } = this.props;

        return (
            <div className="card">
                <div className="card-header d-sm-flex align-items-start justify-content-between">
                    <h6 className="lh-5 mg-b-0">Punishments</h6>
                </div>

                <div className="card-body pd-y-15 pd-x-10">
                    <div className="table-responsive">
                        <table className="table table-borderless table-sm tx-13 tx-nowrap mg-b-0">
                            <thead>
                                <tr className="tx-10 tx-spacing-1 tx-color-03 tx-uppercase">
                                    <th>Time</th>
                                    <th>Action</th>
                                    <th>Duration</th>
                                    <th>Staff member</th>
                                    <th>Reason</th>
                                </tr>
                            </thead>
                            <tbody>
                                {punishments && punishments.map(log =>
                                    <tr key={log.uid}>
                                        <td><ReactTimeago date={log.time} /></td>
                                        <td>{log.type}</td>
                                        <td>{this.getDuration(log)}</td>
                                        <td><Link to={`/characters/${log.staff_character_id}`}>{log.staff_name}</Link></td>
                                        <td>{log.reason || <i>No reason specified.</i>}</td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        );
    }

    getDuration(log) {
        const length = (new Date(log.expires) - new Date(log.time)) / 1000;
        const lengthTxt = length >= 3600 ? ((length / 3600).toFixed() + " hour(s)") : ((length / 60).toFixed() + " min(s)");
        return lengthTxt;
    }

}

class Logins extends React.Component {

    render() {
        const { logins } = this.props;

        return (
            <div className="card">
                <div className="card-header d-sm-flex align-items-start justify-content-between">
                    <h6 className="lh-5 mg-b-0">Logins</h6>
                </div>

                <div className="card-body pd-y-15 pd-x-10">
                    <div className="table-responsive table-striped" style={{ maxHeight: 1080 }}>
                        <table className="table table-borderless table-sm tx-13 tx-nowrap mg-b-0">
                            <thead>
                                <tr className="tx-10 tx-spacing-1 tx-color-03 tx-uppercase">
                                    <th>Time</th>
                                    <th>IP</th>
                                    <th>Version</th>
                                    <th>Device Name</th>
                                    <th>Device Model</th>
                                    <th>Device ID</th>
                                </tr>
                            </thead>
                            <tbody>
                                {logins.map(log =>
                                    <tr key={log.id}>
                                        <td>{log.time}</td>
                                        <td><Link to={`/search/ip/${log.ip}`}>{log.ip}</Link></td>
                                        <td>{log.version}</td>
                                        <td>{log.device_name}</td>
                                        <td>{log.device_model}</td>
                                        <td><Link to={`/search/device/${log.device_id}`}>{log.device_id}</Link></td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        );
    }

}

class NameChanges extends React.Component {

    constructor(props) {
        super(props);
    }

    render() {
        const { nameChanges } = this.props;

        return (
            <div className="card">
                <div className="card-header d-sm-flex align-items-start justify-content-between">
                    <h6 className="lh-5 mg-b-0">Name history</h6>
                </div>

                <div className="card-body pd-y-15 pd-x-10">
                    <div className="table-responsive table-striped" style={{ maxHeight: 1080 }}>
                        <table className="table table-borderless table-sm tx-13 tx-nowrap mg-b-0">
                            <thead>
                                <tr className="tx-10 tx-spacing-1 tx-color-03 tx-uppercase">
                                    <th>Time</th>
                                    <th>Old name</th>
                                    <th>New name</th>
                                </tr>
                            </thead>
                            <tbody>
                                {nameChanges.map(log =>
                                    <tr key={log.id}>
                                        <td>{log.time}</td>
                                        <td>{log.old}</td>
                                        <td>{log.new}</td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        );
    }

}

class Purchases extends React.Component {

    render() {
        const { purchases } = this.props;

        return (
            <div className="card">
                <div className="card-header d-sm-flex align-items-start justify-content-between">
                    <h6 className="lh-5 mg-b-0">Purchases</h6>
                </div>

                <div className="card-body pd-y-15 pd-x-10">
                    <div className="table-responsive table-striped" style={{ maxHeight: 1080 }}>
                        <table className="table table-borderless table-sm tx-13 tx-nowrap mg-b-0">
                            <thead>
                                <tr className="tx-10 tx-spacing-1 tx-color-03 tx-uppercase">
                                    <th>Time</th>
                                    <th>Price</th>
                                    <th>Product</th>
                                    <th>Order Id</th>
                                </tr>
                            </thead>
                            <tbody>
                                {purchases.map(log =>
                                    <tr key={log.id}>
                                        <td>{log.created_at}</td>
                                        <td>€{numberFormat(log.shards / 100.0, 2)}</td>
                                        <td>{log.product_id}</td>
                                        <td>{log.google_id}</td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        );
    }

}

class Cosmetics extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            selectedCosmetic: undefined
        };

        this.giveCosmetic = this.giveCosmetic.bind(this);
    }

    giveCosmetic() {
        const { character } = this.props;

        const selectedId = this.state.selectedCosmetic | 0;
        const selectedName = dataServices.getCosmetics().filter(c => c.id === selectedId)[0].name;

        if (window.confirm("You are about to give: \"" + selectedName + "\"")) {
            apiServices.axios.post(`/characters/${character.id}/cosmetics/add`, { type: selectedId });
        }
    }

    render() {
        const { cosmetics, character } = this.props;

        const cosmeticData = dataServices.getCosmetics() || [];

        return (
            <div className="card">
                <div className="card-header d-sm-flex align-items-start justify-content-between">
                    <h6 className="lh-5 mg-b-0">Cosmetics</h6>
                </div>

                <div className="card-body pd-y-15 pd-x-10">
                    <div className="input-group pd-y-15 pd-x-10">
                        <select className="form-control" onChange={(e) => this.state.selectedCosmetic = e.target.value}>
                            {cosmeticData.filter(c => !character.unlocked_cosmetics.some(c2 => c2 == c.id)).map(c =>
                                <option value={c.id}>
                                    {c.name}
                                </option>
                            )}
                        </select>
                        <div className="input-group-append">
                            <button className="btn btn-outline-light" type="button" onClick={this.giveCosmetic}>Give cosmetic</button>
                        </div>
                    </div>

                    <div className="table-responsive table-striped" style={{ maxHeight: 1080 }}>
                        <table className="table table-borderless table-sm tx-13 tx-nowrap mg-b-0">
                            <thead>
                                <tr className="tx-10 tx-spacing-1 tx-color-03 tx-uppercase">
                                    <th>Time</th>
                                    <th>Name</th>
                                    <th>Shards</th>
                                </tr>
                            </thead>
                            <tbody>
                                {cosmetics.map(log =>
                                    <tr key={log.id}>
                                        <td>{log.time}</td>
                                        <td>{log.name}</td>
                                        <td>{numberFormat(log.shards, 0)}</td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        );
    }

}

class ShardPurchases extends React.Component {

    render() {
        const { shards } = this.props;

        return (
            <div className="card">
                <div className="card-header d-sm-flex align-items-start justify-content-between">
                    <h6 className="lh-5 mg-b-0">Shard Purchases</h6>
                </div>

                <div className="card-body pd-y-15 pd-x-10">
                    <div className="table-responsive table-striped" style={{ maxHeight: 1080 }}>
                        <table className="table table-borderless table-sm tx-13 tx-nowrap mg-b-0">
                            <thead>
                                <tr className="tx-10 tx-spacing-1 tx-color-03 tx-uppercase">
                                    <th>Time</th>
                                    <th>Item</th>
                                    <th>Amount</th>
                                    <th>Shards</th>
                                </tr>
                            </thead>
                            <tbody>
                                {shards.map(log =>
                                    <tr key={log.id}>
                                        <td>{log.time}</td>
                                        <td>{log.name}</td>
                                        <td>{log.amount}</td>
                                        <td>{numberFormat(log.total_shards, 0)}</td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        );
    }

}


class RareDrops extends React.Component {

    render() {
        const { drops } = this.props;

        return (
            <div className="card">
                <div className="card-header d-sm-flex align-items-start justify-content-between">
                    <h6 className="lh-5 mg-b-0">Rare Drops</h6>
                </div>

                <div className="card-body pd-y-15 pd-x-10">
                    <div className="table-responsive table-striped" style={{ maxHeight: 1080 }}>
                        <table className="table table-borderless table-sm tx-13 tx-nowrap mg-b-0">
                            <thead>
                                <tr className="tx-10 tx-spacing-1 tx-color-03 tx-uppercase">
                                    <th>Time</th>
                                    <th>Item</th>
                                    <th>Amount</th>
                                </tr>
                            </thead>
                            <tbody>
                                {drops.map(log =>
                                    <tr key={log.id}>
                                        <td>{log.time}</td>
                                        <td>{log.name}</td>
                                        <td>{log.quantity}</td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        );
    }

}


class Reports extends React.Component {

    render() {
        const { reports } = this.props;

        this.reportCard = this.reportCard.bind(this);

        return (
            <div style={{ maxHeight: 1180, overflow: 'scroll' }}>
                {reports.map(r => this.reportCard(r))}
            </div>
        );
    }

    reportCard(report) {
        return (
            <div className="card mb-2">
                <div className="card-header tx-medium">
                    Report #{report.uid}: <Link to={`/characters/${report.target_id}`}>{report.target_name}</Link>
                    {report.banned && <span className="badge badge-danger">Banned</span>}
                    {report.muted && <span className="badge badge-warning">Muted</span>}
                    <OnlineBadge id={report.target_id} /> for <b>{Report.getReason(report.reason_id)}</b>

                    {report.handled && <span className="badge badge-success">Handled</span>}

                    <span className="text-muted ml-2 small">{report.time} | Reported by <Link to={`/characters/${report.reporter_id}`}>{report.reporter_name}</Link> on World {report.world_id}</span>
                </div>

                <div className="card-body">
                    <div className="card-text">
                        <div className="row">
                            <div className="col-md-6">
                                <h5>Summary</h5>

                                {Report.generateInformation(report)}
                            </div>

                            <div className="col-md-6">
                                <h5>Team activity</h5>

                                {report.assignee === 0 ? <span>Report is not assigned to anyone.</span> : <span>Report is assigned to <Link to={`/characters/${report.assignee}`}>{report.assignee_name}</Link></span>}<br />
                                Report is not closed by anyone.<br />
                                No action is yet taken.

                                <hr />

                                <h5>User activity</h5>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

}


class Notes extends React.Component {

    constructor(props) {
        super(props);
    }

    render() {
        const { notes } = this.props;

        let list = notes.length && notes.map(note => (
            <div className="card mg-b-20 mg-lg-b-25">
                <div className="card-header pd-y-15 pd-x-20 d-flex align-items-center justify-content-between">
                    <h6 className="tx-semibold mg-b-0">{note.staff_name} {note.generated && <span class="badge badge-success ml-2">System Generated</span>}</h6>
                    <nav className="nav nav-with-icon tx-13">
                        <span className="text-muted"><Clock /> {note.time}</span>
                    </nav>
                </div>

                <div className="card-body pd-25">
                    <div className="media d-block d-sm-flex">
                        {note.message}
                    </div>
                </div>

                <div className="card-footer bg-transparent pd-y-15 pd-x-20">
                    <nav className="nav nav-with-icon tx-13">
                        <a href="" className="nav-link">
                            <Trash /> Delete
                        </a>
                    </nav>
                </div>
            </div>
        ));

        return (
            <div>{list || <span className="text-muted">There are no notes for this character yet.</span>}</div>
        );
    }

}

class Inventories extends React.Component {

    render() {
        const { inventory, equipment, bank } = this.props.character;
        const itemData = dataServices.getItems();

        let index = 0;
        let inventoryRows = inventory.map(item => item &&
            <tr key={index++}>
                <td>{itemData[item.id] ? itemData[item.id].name : "<Unknown>"}</td>
                <td>{item.amount}</td>
            </tr>
        );

        index = 0;
        let equipmentRows = equipment.map(item => item &&
            <tr key={index++}>
                <td>{itemData[item.id] ? itemData[item.id].name : "<Unknown>"}</td>
                <td>{item.amount}</td>
            </tr>
        );

        index = 0;
        let bankRows = bank.map(item => item &&
            <tr key={index++}>
                <td>{itemData[item.id] ? itemData[item.id].name : "<Unknown>"}</td>
                <td>{item.amount}</td>
            </tr>
        );

        return (
            <div>
                <div className="row">
                    <div className="col-md-12">
                        <div className="card">
                            <div className="card-header d-sm-flex align-items-start justify-content-between">
                                <h6 className="lh-5 mg-b-0">Inventory</h6>
                            </div>

                            <div className="card-body pd-y-15 pd-x-10">
                                <div className="table-responsive">
                                    <table className="table table-borderless table-sm tx-13 tx-nowrap mg-b-0 table-striped">
                                        <thead>
                                            <tr className="tx-10 tx-spacing-1 tx-color-03 tx-uppercase">
                                                <th>Item</th>
                                                <th>Amount</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {inventoryRows}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="row mt-2">
                    <div className="col-md-12">
                        <div className="card">
                            <div className="card-header d-sm-flex align-items-start justify-content-between">
                                <h6 className="lh-5 mg-b-0">Equipment</h6>
                            </div>

                            <div className="card-body pd-y-15 pd-x-10">
                                <div className="table-responsive">
                                    <table className="table table-borderless table-sm tx-13 tx-nowrap mg-b-0 table-striped">
                                        <thead>
                                            <tr className="tx-10 tx-spacing-1 tx-color-03 tx-uppercase">
                                                <th>Item</th>
                                                <th>Amount</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {equipmentRows}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="row mt-2">
                    <div className="col-md-12">
                        <div className="card">
                            <div className="card-header d-sm-flex align-items-start justify-content-between">
                                <h6 className="lh-5 mg-b-0">Bank</h6>
                            </div>

                            <div className="card-body pd-y-15 pd-x-10">
                                <div className="table-responsive">
                                    <table className="table table-borderless table-sm tx-13 tx-nowrap mg-b-0 table-striped">
                                        <thead>
                                            <tr className="tx-10 tx-spacing-1 tx-color-03 tx-uppercase">
                                                <th>Item</th>
                                                <th>Amount</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {bankRows}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

}

const mapStateToProps = (state) => {
    return {};
}

//const connected = connect(mapStateToProps)(CharacterView);
//export {connected as CharacterView};
export { CharacterView };
