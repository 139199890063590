import React from "react";
import { Bar } from 'react-chartjs-2';


class RevenueChart extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            chartKey: 'finances24',
            iosAndroid: true
        };

        this.switch = this.switch.bind(this);
    }

    switch(key) {
        this.setState({ chartKey: key });
    }

    render() {
        const graph = this.props.data[this.state.chartKey] || [];
        const iosAndroid = this.state.iosAndroid;

        let labels = [];
        let androidData = [];
        let iosData = [];

        graph.forEach(a => {
            labels.push(a.date);

            if (iosAndroid) {
                androidData.push(a.android_revenue.toFixed());
                iosData.push(a.ios_revenue.toFixed());
            } else {
                androidData.push(a.shard_revenue.toFixed());
                iosData.push(a.wish_revenue.toFixed());
            }
        });

        const chartData = {
            labels: labels,
            datasets: [
                {
                    label: iosAndroid ? 'Android' : 'Shards',
                    backgroundColor: iosAndroid ? 'rgba(1, 104, 250, 0.2)' : 'rgba(255, 0, 186, 0.2)',
                    borderColor: iosAndroid ? 'rgba(1, 104, 250, 1)' : 'rgba(255, 0, 186, 1)',
                    borderWidth: 1,
                    hoverBackgroundColor: iosAndroid ? 'rgba(1, 104, 250, 0.4)' : 'rgba(255, 0, 186, 0.4)',
                    hoverBorderColor: iosAndroid ? 'rgba(1, 104, 250, 1)' : 'rgba(255, 0, 186, 1)',
                    data: androidData,
                    scaleLabel:
                        function (label) { return '&euro;' + label.value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","); },
                },
                {
                    label: iosAndroid ? 'iOS' : 'Lamps',
                    backgroundColor: iosAndroid ? 'rgba(250, 0, 15, 0.2)' : 'rgba(255, 255, 0, 0.2)',
                    borderColor: iosAndroid ? 'rgba(250, 0, 15, 1)' : 'rgba(255, 255, 0, 1)',
                    borderWidth: 1,
                    hoverBackgroundColor: iosAndroid ? 'rgba(250, 0, 15, 0.4)' : 'rgba(255, 255, 0, 0.4)',
                    hoverBorderColor: iosAndroid ? 'rgba(250, 0, 15, 1)' : 'rgba(255, 255, 0, 1)',
                    data: iosData,
                    scaleLabel:
                        function (label) { return '&euro;' + label.value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","); },
                }
            ]
        };

        return (
            <div className="card">
                <div className="card-header d-sm-flex align-items-start justify-content-between">
                    <h6 className="lh-5 mg-b-0 flex-1">Revenue</h6>

                    <input type="checkbox" checked={iosAndroid} onChange={e => this.setState({ iosAndroid: e.target.checked })}></input>Toggle Split Type<br />

                    <select value={this.state.chartKey} className="form-control form-control-sm flex-1 finance-selector" onChange={(e) => this.switch(e.target.value)}>
                        <option value="finances24">Last 24 hours</option>
                        <option value="finances7d">Last week</option>
                        <option value="finances31d">Last 31 days</option>
                        <option value="financesMTD">Current month</option>
                        <option value="finances365d">Last year (daily)</option>
                        <option value="finances365dW">Last year (weekly)</option>
                        <option value="finances365dM">Last year (monthly)</option>
                        <option value="lifetimeM">Lifetime (monthly)</option>
                    </select>
                </div>

                <div className="card-body pd-y-15 pd-x-10">
                    <Bar data={chartData} options={{
                        tooltips: {
                            callbacks: {
                                label: function (tooltipItem, data) {
                                    var label = [];
                                    var sum = 0;
                                    for (var i in data.datasets) {
                                        label.push((data.datasets[i].label || '') + ': €' + data.datasets[i].data[tooltipItem.index].toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","));
                                        sum += data.datasets[i].data[tooltipItem.index] | 0;
                                    }
                                    label.push('Total: €' + sum.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","));
                                    return label;
                                }
                            }
                        },
                        hover: { intersect: false }, scales: {
                            yAxes: [{
                                stacked: true,
                                ticks: {
                                    beginAtZero: true,
                                    callback: function (value, index, values) {
                                        return '€' + value.toFixed().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                                    }
                                }
                            }],
                            xAxes: [{ stacked: true }]
                        }
                    }} />
                </div>
            </div>
        );
    }

}

export default RevenueChart;
