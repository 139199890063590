import React from 'react';
import {apiServices} from "./_services";
import TimeAgo from "react-timeago/lib";

export class PatronList extends React.Component {

    render() {
        const {patrons, activeOnly} = this.props;

        return (
            <div>
                <div className="table-responsive">
                    <table className="table table-striped table-borderless table-sm tx-13 tx-nowrap mg-b-0">
                        <thead>
                        <tr className="tx-10 tx-spacing-1 tx-color-03 tx-uppercase">
                            <th>Name</th>
                            <th>Patreon Email</th>
                            <th>Tier</th>
                            <th>Linked to</th>
                            <th>Emailed</th>
                            <th>Member since</th>
                            <th>Last charge</th>
                            <th>Actions</th>
                        </tr>
                        </thead>

                        <tbody>
                        { patrons.filter(p => !activeOnly || p.tier > 0).map(p => <PatronEntry patron={p} />)}
                        </tbody>
                    </table>
                </div>
            </div>
        )
    }

}

function PatronEntry(props) {
    const {patron} = props;
    return (
        <tr key={patron.patreon_id}>
            <td>{patron.name} {patron.tier === 0 && <span className="badge badge-warning">Inactive</span> }</td>
            <td>{patron.email}</td>
            <td>{tierToStr(patron.tier)}<br/>{patron.status}</td>
            <td>{patron.discord_tag || <i>Discord unlinked</i>}<br/>{patron.ign || <i>IGN unlinked</i>}</td>
            <td><TimeAgo date={patron.links_emailed_at}/></td>
            <td>{patron.patron_since}</td>
            <td><TimeAgo date={patron.last_charge}/><br/>{patron.last_charge_status}</td>
            <td>
                <button className="btn btn-outline-warning btn-xs" onClick={() => {window.confirm(`Are you sure you want to email ${patron.name} (${patron.email})?`) && apiServices.axios.post(`/patreon/patrons/${patron.patreon_id}/email`)}}>Email Links</button>
                <button className="btn btn-outline-warning btn-xs" onClick={() => apiServices.axios.post(`/patreon/patrons/${patron.patreon_id}/sync`)}>Sync</button>
            </td>
        </tr>
    )
}

function tierToStr(tier) {
    if (tier === 0) {
        return "None";
    } else if (tier === 1) {
        return "Regular";
    } else if (tier === 2) {
        return "Cursed";
    }

    return "?";
}

