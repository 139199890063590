import React from "react";
import { Link } from 'react-router-dom';
import Humanize from 'humanize';
import ReactCountryFlag from "react-country-flag";
import ReactTimeago from "react-timeago";
import { countries } from 'country-data';


class LatestTransactions extends React.Component {

    constructor(props) {
        super(props);
        this.state = { limit: 200 };
    }

    switch = () => { }

    render() {
        const { finances } = this.props;
        const { limit } = this.state;
        const data = finances.latestTransactions;
        const canLoadMore = data && limit < data.length;

        return (
            <div className="card">
                <div className="card-header d-sm-flex align-items-start justify-content-between">
                    <h6 className="lh-5 mg-b-0">Latest Transactions</h6>
                </div>

                <div className="card-body pd-y-15 pd-x-10">
                    <div className="table-responsive table-striped" style={{ maxHeight: 320 }}>
                        <table className="table table-borderless table-sm tx-13 tx-nowrap mg-b-0">
                            <thead>
                                <tr className="tx-10 tx-spacing-1 tx-color-03 tx-uppercase">
                                    <th></th>
                                    <th style={{ paddingLeft: 0 }}>Name</th>
                                    <th>Product</th>
                                    <th>Shekels</th>
                                    <th>Time</th>
                                </tr>
                            </thead>

                            <tbody>
                                {data && data.slice(0, Math.min(limit, data.length)).map(c => <tr key={c.time}>
                                    <td style={{ padding: '5px 5px', width: 20 }}><ReactCountryFlag title={countries[c.country] && countries[c.country].name} countryCode={c.country} svg style={{ fontSize: '1.5em', lineHeight: '1.5em', }} /></td>
                                    <td style={{ paddingLeft: 0 }}><Link to={`/characters/${c.character_id}`}><b style={{ color: c.members ? '#ffa500' : 'auto' }}>{c.name}</b></Link></td>
                                    <td>{getProductName(c.product_id)}</td>
                                    <td>&euro; {((c.shards / 100.0) * 0.86).toFixed()}</td>
                                    <td><ReactTimeago date={c.time * 1000} /></td>
                                </tr>)}
                            </tbody>
                        </table>
                    </div>
                </div>

                <div className="card-footer">
                    <button className="btn btn-block btn-dark btn-xs" disabled={!canLoadMore} onClick={() => this.setState({ limit: limit + 10 })}>Load more</button>
                </div>
            </div>
        );
    }

}

function getProductName(id) {
    switch (id) {
        case 'shards_100': return '100 Shards';
        case 'shards_500': return '500 Shards';
        case 'shards_1500': return '1,500 Shards';
        case 'shards_5000': return '5,000 Shards';

        case 'lamps_5': return '5 Wishes';
        case 'lamps_30': return '30 Wishes';
        case 'lamps_130': return '130 Wishes';
        case 'lamps_500': return '500 Wishes';
        default:
            return "<" + id + ">";
    }
}

export default LatestTransactions;
