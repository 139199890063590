import React from 'react';
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { Crosshair, Home, Plus, RefreshCw, Users } from "react-feather";
import { Route, Switch } from "react-router";
import { apiServices } from "./_services";

class BotWatch extends React.Component {


    constructor(props) {
        super(props);

        this.state = {
            clusters: [],
            activeOnly: true
        };

        // this.refresh = this.refresh.bind();
        this.refresh();
    }

    refresh() {
        apiServices.axios.get('/botclusters.json').then(r => {
            let normalized = [];

            for (let [key, value] of Object.entries(r.data)) {
                normalized.push({ ip: key, players: value });
            }

            normalized.sort((a, b) => b.players.length - a.players.length);

            this.setState({ clusters: normalized });
        });
    }

    render() {
        const { dispatch } = this.props;
        const { clusters, autoUpdate } = this.state;

        return (
            <div className="container-fluid pd-x-0 tx-13">
                <div className="d-sm-flex align-items-center justify-content-between mg-b-20 mg-lg-b-30">
                    <div>
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb breadcrumb-style1 mg-b-10">
                                <li className="breadcrumb-item"><Link to="/">Dashboard</Link></li>
                                <li className="breadcrumb-item active" aria-current="page">BotWatch</li>
                            </ol>
                        </nav>

                        <h4 className="mg-b-0 tx-spacing--1">BotWatch &trade;</h4>
                        <p className="tx-color-03 mg-b-25">KILL KILL KILL.</p>
                    </div>

                    <div className="d-none d-md-block">
                        <button onClick={() => this.refresh()} className="btn btn-sm pd-x-15 btn-white btn-uppercase"><RefreshCw /> Refresh</button>
                        <button className={"btn btn-sm pd-x-15 btn-outline-primary btn-uppercase mg-l-5 " + (autoUpdate && 'active')} onClick={() => this.setState({ autoUpdate: !this.state.autoUpdate })}><Plus /> Auto-update</button>
                    </div>
                </div>

                <div className="row">
                    <div className="col-sm-3 col-md-3 col-xl-2 card">
                        <div className="row">
                            <div className="card-body">
                                <label className="tx-sans tx-10 tx-semibold tx-uppercase tx-color-01 tx-spacing-1 mg-b-15">Navigation</label>

                                <nav className="nav nav-classic tx-13">
                                    <Link to={`/botwatch`} className="nav-link"><Users /> Bot Clusters</Link>
                                </nav>
                            </div>
                        </div>
                    </div>

                    <div className="col-sm-9 col-md-9 col-xl-10">
                        <Switch>
                            <Route exact path="/botwatch" component={() => <BotClusters clusters={clusters} />} />
                        </Switch>
                    </div>
                </div>
            </div>
        );
    }

}

function BotClusters(props) {
    const { clusters } = props;
    console.log(clusters);

    return (<div className="row">{clusters.map(e => <BotCard ip={e.ip} players={e.players} />)}</div>);
}

function banCluster(players, ip) {
    if (window.confirm('Are you sure you want to ban ' + players.length + ' players?')) {
        const involved = players.map(p => p.name).join(', ');
        const reason = 'Cluster-ban on ' + players.length + ' characters for botting (IP: ' + ip + "). Banned characters: " + involved + ".";
        const duration = '10 years';

        players.forEach(player => {
            apiServices.axios.post(`/accounts/${player.account_id}/ban`, { character_id: player.id, reason, duration });
        });
    }

    return false;
}

function BotCard(props) {
    const { ip, players } = props;

    return (
        <div className="col-md-6">
            <div className="card mb-2">
                <div className="card-header d-sm-flex align-items-start justify-content-between">
                    <h6 className="lh-5 mg-b-0"><Users /> Cluster <Link to={`/search/ip/${ip}`}>{ip}</Link> ({players.length})</h6>

                    <div style={{}}><button className='btn btn-xs btn-danger' onClick={() => banCluster(players, ip)}>Ban cluster</button></div>
                </div>

                <div className="card-body pd-y-15 pd-x-10">
                    <div className="table-responsive">
                        <table className="table table-striped table-borderless table-sm tx-13 tx-nowrap mg-b-0">
                            <thead>
                                <tr className="tx-10 tx-spacing-1 tx-color-03 tx-uppercase">
                                    <th>Name</th>
                                    <th>World</th>
                                    <th>Email</th>
                                    <th>Playtime</th>
                                    <th>Session</th>
                                </tr>
                            </thead>

                            <tbody>
                                {players.map(e => <tr>
                                    <td><Link to={`/characters/${e.id}`}>{e.name}</Link></td>
                                    <td>{e.world}</td>
                                    <td>{e.email}</td>
                                    <td>{e.playtime}</td>
                                    <td><SessionTimer date={e.since} /></td>
                                </tr>)}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    );
}

const SessionTimer = React.memo(({ date }) => {
    const timerTime = Date.now() - date;
    let seconds = ("0" + (Math.floor(timerTime / 1000) % 60)).slice(-2);
    let minutes = ("0" + (Math.floor(timerTime / 60000) % 60)).slice(-2);
    let hours = ("0" + Math.floor(timerTime / 3600000)).slice(-2);
    return <span>{hours}:{minutes}:{seconds}</span>;
});

function mapStateToProps(state) {
    return {};
}

const connected = connect(mapStateToProps)(BotWatch);
export { connected as BotWatch };