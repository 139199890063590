import React from 'react';
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { Home} from "react-feather";
import { apiServices } from "./_services";
import { OnlineBadge } from 'OnlineBadge';
import guardianImg from './css/images/ranks/guardian.png';
import ReactTimeago from 'react-timeago';

class Guardians extends React.Component {


    constructor(props) {
        super(props);

        this.state = {
            guardians: []
        };

        apiServices.axios.get('/guardians').then(r => {
            this.setState({ guardians: r.data });
        });
    }

    render() {
        const { guardians } = this.state;

        return (
            <div className="container-fluid pd-x-0 tx-13">
                <div className="d-sm-flex align-items-center justify-content-between mg-b-20 mg-lg-b-30">
                    <div>
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb breadcrumb-style1 mg-b-10">
                                <li className="breadcrumb-item"><Link to="/">Dashboard</Link></li>
                                <li className="breadcrumb-item active" aria-current="page">Guardians</li>
                            </ol>
                        </nav>

                        <h4 className="mg-b-0 tx-spacing--1">Guardian Team Management</h4>
                        <p className="tx-color-03 mg-b-25">There are 0 people in the Guardians team.</p>
                    </div>
                </div>

                <div className="row">
                    <div className="col-sm-3 col-md-3 col-xl-1 card">
                        <div className="row">
                            <div className="card-body">
                                <label className="tx-sans tx-10 tx-semibold tx-uppercase tx-color-01 tx-spacing-1 mg-b-15">Navigation</label>

                                <nav className="nav nav-classic tx-13">
                                    <Link to={`/guardians`} className="nav-link"><Home /> Guardians List</Link>
                                </nav>
                            </div>
                        </div>
                    </div>

                    <div className="col-sm-9 col-md-9 col-xl-11">
                        <div className="row">
                            {guardians.map(g => <>
                                <div className="col-sm-12 col-md-12 col-lg-4 col-xl-2 card m-2">
                                    <div className="card-body">
                                        <h4><img alt="" src={guardianImg} width={24} height={24} style={{ marginBottom: 5 }} /> <Link to={`/characters/${g.id}`} style={{ color: '#' + g.name_color }}>{g.name}</Link> <OnlineBadge id={g.id} lastOnline={g.last_online} /></h4>

                                        {g.punishments && g.punishments.length ?
                                            <>{g.punishments.map(p => <div key={p.uid} style={{ marginLeft: -20, marginRight: -20, padding: 8, border: '1px solid #11151f', borderRadius: 4, backgroundColor: '#181d2c' }}>
                                                {punishTitle(p)}
                                            </div>)}</>
                                            : <p>No punishments issued yet.</p>}
                                    </div>
                                </div>
                            </>)}
                        </div>
                    </div>
                </div>
            </div>
        );
    }

}

function punishTitle(p) {
    const length = (new Date(p.expires) - new Date(p.time)) / 1000;
    const lengthTxt = length >= 3600 ? ((length / 3600).toFixed() + " hour(s)") : ((length / 60).toFixed() + " min(s)");
    if (p.type === 'mute') {
        return <><div style={{ fontSize: 14 }}>Muted <Link style={{ margin: '0 4px' }} to={`/characters/${p.character_id}`}><b>{p.name}</b></Link> for {lengthTxt} <ReactTimeago style={{ float: 'right', fontSize: 11, marginLeft: 4, color: '#808080' }} date={p.time} /></div>
            <span style={{ color: '#a0a0a0' }}>Reason: <i>"{p.reason}"</i></span></>
    }

    return "<unknown punishment>";
}


function mapStateToProps(state) {
    return {};
}

const connected = connect(mapStateToProps)(Guardians);
export { connected as Guardians };
