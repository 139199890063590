import React from 'react';
import {connect} from 'react-redux';
import {Link} from 'react-router-dom';
import {RefreshCw} from 'react-feather';
import {apiServices} from './_services';
import queryString from 'query-string';
import levenshtein from 'js-levenshtein';
import {xpToLevel} from './_helpers';
import {OnlineBadge} from "./OnlineBadge";

class SearchDeviceId extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            query: this.props.match.params.device || '',
            characters: [],
            character: null,
        };

        this.search = this.search.bind(this);
    }

    shouldComponentUpdate(props, state) {
        return this.props !== props || this.state !== state;
    }

    componentDidUpdate() {
        const query = queryString.parse(this.props.location.search).q;
        if (query !== this.state.query) {
            //this.search(query);
        }
    }

    componentDidMount() {
        this.search(this.state.query);
    }

    search(query) {
        apiServices.axios.get('/search/characters/by-device.json?device_id=' + encodeURIComponent(query)).then(resp => {
            this.setState({characters: resp.data});
        });
    }

    render() {
        const {characters, query} = this.state;

        return (
            <div className="container pd-x-0 tx-13">
                <div className="d-sm-flex align-items-center justify-content-between mg-b-20 mg-lg-b-30">
                    <div>
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb breadcrumb-style1 mg-b-10">
                                <li className="breadcrumb-item"><Link to="/">Dashboard</Link></li>
                                <li className="breadcrumb-item active" aria-current="page">Search</li>
                            </ol>
                        </nav>

                        <h4 className="mg-b-0 tx-spacing--1">Search results for Device ID ({query})</h4>
                    </div>

                    <div className="d-none d-md-block">
                        <button className="btn btn-sm pd-x-15 btn-white btn-uppercase"
                                onClick={() => this.search(this.state.query)}><RefreshCw/> Refresh
                        </button>
                    </div>
                </div>

                {characters && characters.length ? <CharacterResults characters={characters}/> : <Spinner/>}
            </div>
        );
    }

}

const CharacterResults = (props) => (
    <div className="card">
        <div className="card-header d-sm-flex align-items-start justify-content-between">
            <h6 className="lh-5 mg-b-0">Characters</h6>
        </div>

        <div className="card-body pd-y-15 pd-x-10">
            <div className="table-responsive">
                <table className="table table-borderless table-sm tx-13 tx-nowrap mg-b-0">
                    <thead>
                    <tr className="tx-10 tx-spacing-1 tx-color-03 tx-uppercase">
                        <th>Name</th>
                        <th>Level</th>
                        <th>Email</th>
                        <th>Last online</th>
                        <th>Registered</th>
                    </tr>
                    </thead>
                    <tbody>
                    {props.characters.map(player =>
                        <tr key={player.id}>
                            <td><Link to={`/characters/${player.id}`}>{player.name}</Link> <OnlineBadge id={player.id} /> {player.muted &&
                            <span className="badge badge-warning ml-2">Muted</span>} {player.banned &&
                            <span className="badge badge-danger ml-2">Banned</span>}</td>
                            <td>{xpToLevel(player.xp)}</td>
                            <td>{player.email}</td>
                            <td>{player.last_online}</td>
                            <td>{player.created_at}</td>
                        </tr>
                    )}
                    </tbody>
                </table>
            </div>
        </div>
    </div>
);

const Spinner = (props) => (<div className="text-center">
    <div className="spinner-border"/>
</div>);

export {SearchDeviceId};
