import { financesServices } from "../_services";
import { financesConstants } from "../_constants";

export const financesActions = {
    updateFinances
};

function updateFinances() {
    return dispatch => {
        financesServices.updateFinances().then(data => {
            dispatch({ type: financesConstants.UPDATE_FINANCES_RESPONSE, payload: data })
        });
        financesServices.updateFTB().then(data => {
            dispatch({ type: financesConstants.UPDATE_FINANCES_RESPONSE, payload: data })
        });
    };
}
