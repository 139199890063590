import React from "react";
import { Link } from 'react-router-dom';
import Humanize from 'humanize';


class TopBoosters extends React.Component {

    constructor(props) {
        super(props);
        this.state = { limit: 100 };
    }

    switch = () => { }

    render() {
        const { finances } = this.props;
        const { limit } = this.state;
        const data = finances.topBoosters;
        const canLoadMore = data && limit < data.length;

        return (
            <div className="card">
                <div className="card-header d-sm-flex align-items-start justify-content-between">
                    <h6 className="lh-5 mg-b-0 flex-1">Top Boosters</h6>

                    <select value={"topCosmetics"} className="form-control form-control-sm flex-1 finance-selector" onChange={(e) => this.setState({ key: e.target.value })}>
                        <option value="topCosmetics">Overall</option>
                        <option value="topCosmetics31d" disabled>Last 31 days</option>
                    </select>
                </div>

                <div className="card-body pd-y-15 pd-x-10">
                    <div className="table-responsive table-striped" style={{ maxHeight: 320 }}>
                        <table className="table table-borderless table-sm tx-13 tx-nowrap mg-b-0">
                            <thead>
                                <tr className="tx-10 tx-spacing-1 tx-color-03 tx-uppercase">
                                    <th></th>
                                    <th>Name</th>
                                    <th>Boosts</th>
                                    <th>Total Shards</th>
                                    <th>Profit</th>
                                </tr>
                            </thead>

                            <tbody>
                                {data && data.map(c => <tr key={c.min}>
                                    <td>#{data.indexOf(c) + 1}</td>
                                    <td><Link to=""><b>{c.min}</b></Link></td>
                                    <td>{Humanize.numberFormat(c.count, 0)}</td>
                                    <td>{Humanize.numberFormat(c.sum, 0)}</td>
                                    <td>&euro; {Humanize.numberFormat(c.sum / 86.0, 0, '.', ',')}</td>
                                </tr>)}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        );
    }

}

export default TopBoosters;
