import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { store } from "../_helpers";

export const PrivateRoute = ({ component: Component, ...rest }) => (
    <Route {...rest} render={props => render(Component, props)} />
);

function render(Component, props) {
    // If not authenticated, trigger a redirect to the login page.
    if (!isAuthenticated()) {
        return <Redirect to={{ pathname: '/login', state: { from: props.location } }} />;
    }

    // Render the component with the normal props we're given.
    return <Component {...props} />;
}

function isAuthenticated() {
    const state = store.getState();
    return state && state.authentication && !!state.authentication.token;
}
