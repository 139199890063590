import React from "react";
import { Link } from "react-router-dom";
import { OnlineBadge } from "./OnlineBadge";
import { reportsActions } from "./_actions";
import { numberFormat } from "humanize";
import { connect } from "react-redux";
import guardianImg from './css/images/ranks/guardian.png';
import ReactTimeago from "react-timeago";

class Report extends React.Component {

    constructor(props) {
        super(props);

        this.selfAssign = this.selfAssign.bind(this);
        this.unassign = this.unassign.bind(this);
        this.close = this.close.bind(this);
        this.fetchChatlog = this.fetchChatlog.bind(this);
    }

    render() {
        const { report, accountId } = this.props;

        return (
            <div className="card mb-2">
                <div className="card-header tx-medium text-muted">
                    <span style={{ fontSize: 16 }}>#{report.uid}: <Link to={`/characters/${report.target_id}`} style={{ marginRight: 8 }}>{report.target_name}</Link>
                        {report.banned && <span className="badge badge-danger">Banned</span>}
                        {report.muted && <span className="badge badge-warning">Muted</span>}
                        <OnlineBadge id={report.target_id} />
                    </span>

                    <br />Reported by <img src={guardianImg} height={14} /> <Link style={{ color: '#8db8e0' }} to={`/characters/${report.reporter_id}`}>{report.reporter_name}</Link> for <b style={{ color: '#ff6060' }}>{Report.getReason(report.reason_id)}</b>

                    {report.handled && <span className="badge badge-success">Handled</span>}

                    <div style={{ top: 16, right: 16, position: 'absolute' }}>
                        <ReactTimeago date={report.time} /><br />

                        <span className="text-muted ml-2 small"> on World {report.world_id}</span>
                    </div>
                </div>

                <div className="card-body">
                    <div className="card-text">
                        <div className="row">
                            <div className="col-md-8">
                                {Report.generateInformation(report)}
                            </div>

                            <div className="col-md-4">
                                <h5>Team activity</h5>

                                {report.assignee === 0 ? <span>Report is not assigned to anyone.</span> : <span>Report is assigned to <Link to={`/characters/${report.assignee}`}>{report.assignee_name}</Link></span>}<br />

                                <hr />

                                <h5>Infractions</h5>
                                {Report.generateUserActivity(report)}
                            </div>
                        </div>
                    </div>
                </div>

                {report.assignee === accountId ? this.actionsFooter() : this.assignToMeFooter()}
            </div>
        );
    }

    selfAssign() {
        this.props.dispatch(reportsActions.assignToMe(this.props.report.uid));
    }

    unassign() {
        this.props.dispatch(reportsActions.unassign(this.props.report.uid));
    }

    close() {
        this.props.dispatch(reportsActions.close(this.props.report.uid, true));
    }

    fetchChatlog() {
        this.props.dispatch(reportsActions.loadChat(this.props.report.uid));
    }

    actionsFooter() {
        return (
            <div className="card-footer">
                <a href="">Ban</a>
                <a href="" className="mg-l-10">Mute</a>
                <a href="" className="mg-l-10">Change name</a>
                <button className="btn btn-outline-success btn-xs ml-3" onClick={this.close}>Close</button>

                <button className="btn btn-outline-warning btn-xs ml-3" onClick={this.unassign}>Unassign</button>
            </div>
        );
    }

    assignToMeFooter() {
        return (
            <div className="card-footer">
                <button className="btn btn-primary btn-xs" onClick={this.selfAssign}>Assign to me</button>
            </div>
        );
    }

    static generateUserActivity(report) {
        if (report.punishments.length === 0) {
            return <span>User was not involved in recent punishments.</span>
        }

        return report.punishments.map(p => <div key={p.id}>
            {p.type === 'ban' ? <span className="badge badge-danger">Banned</span> : p.type === 'mute' ? <span className="badge badge-warning">Muted</span> : <span className="badge badge-warning">Shadowmuted</span>}
            <span style={{ marginLeft: 4 }}> by {p.name}, </span><ReactTimeago date={p.time} /><br />
            <i className="text-muted">"{p.reason}"</i>
        </div>);
    }

    static getReason(id) {
        return ['Unknown', 'Offensive Language', 'Macroing', 'Offensive Name', 'Bug Abuse', 'RWT', 'Advertising'][id];
    }

    static generateInformation(report) {
        // Macroing:
        if (report.reason_id === 2) {
            return this.generateMacroReport(report);
        }

        if (report.chat_log) {
            return (
                <div style={{ overflowY: 'scroll', maxHeight: 240 }}>
                    <table className="table table-borderless table-sm tx-13 tx-nowrap mg-b-0 table-striped">
                        <thead>
                            <th>Time</th>
                            <th>Message</th>
                        </thead>
                        <tbody>
                            {
                                report.chat_log.map(log =>
                                    <tr key={log.id}>
                                        <td><ReactTimeago date={log.time} /></td><td>{log.message}</td>
                                    </tr>
                                )
                            }
                        </tbody>
                    </table>
                </div>
            );
        }

        return (
            <div>

                <p>User was reported for {Report.getReason(report.reason_id)}{report.total > 1 && ` (${report.total} times)`}.</p>

                <button className="btn btn-outline-warning btn-xs" onClick={this.fetchChatlog}>Load chat excerpt</button>
            </div>
        );
    }

    static generateMacroReport(report) {
        const attachment = JSON.parse(report.attachment);

        return (
            <div>
                <h5>Macroing Summary</h5>
                <p>User was reported for Macroing{report.total > 1 && ` (${report.total} times)`}.</p>

                Macroing apps installed on device:
                <ul>
                    {attachment.macro_apps.map(e => <li key={e}><b>{e}</b></li>)}
                </ul>

                Detection violations:
                <ul>
                    <li><b>Average touches per second</b>: {numberFormat(attachment.violations.AVG_TPS, 0)}</li>
                    <li><b>Doubled touches per second</b>: {numberFormat(attachment.violations.AVG_TPS_DOUBLE, 0)}</li>
                    <li><b>Obscured touches</b>: {numberFormat(attachment.violations.OBSCURED_CLICKS, 0)}</li>
                </ul>

                Touches:
            </div>
        );
    }

}

function mapStateToProps(state, ownProps) {
    const report = findReport(state, ownProps.uid);
    return { account: state.authentication.account, report: report };
}

function findReport(state, uid) {
    const reports = state.reports.reports;

    for (let i = 0; i < reports.length; i++) {
        if (reports[i] && reports[i].uid === uid) {
            return reports[i];
        }
    }

    console.log("Cannot find uid " + uid);
}

const connected = connect(mapStateToProps)(Report);
export { connected as Report };
