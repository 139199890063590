import React from "react";
import { connect } from "react-redux";
import { RefreshCw, Save } from "react-feather";
import { Bar } from 'react-chartjs-2';
import { Link } from 'react-router-dom';
import { apiServices } from "_services";

class ProductFinances extends React.Component {

    constructor(props) {
        super(props);

        this.state = { chart: [], name: '' };
    }

    componentDidMount() {
        const id = this.props.match.params.id;
        const is_generic = this.props.match.params.generic;

        apiServices.axios.get((is_generic ? '/finances/products/generic/' : '/finances/products/') + id).then(r => {
            this.setState({ chart: r.data.chart, name: r.data.name });
        });
    }

    render() {
        const { account, finances, statistics, match } = this.props;
        const { chart, name } = this.state;

        let labels = [];
        let data = [];

        chart.forEach(a => {
            labels.push(a.date_trunc);
            data.push(a.revenue.toFixed());
        });

        const chartData = {
            labels,
            datasets: [
                {
                    label: 'Revenue',
                    backgroundColor: 'rgba(1, 104, 250, 0.2)',
                    borderColor: 'rgba(1, 104, 250)',
                    borderWidth: 1,
                    hoverBackgroundColor: 'rgba(1, 104, 250, 0.4)',
                    hoverBorderColor: 'rgba(1, 104, 250, 1)',
                    data,
                    scaleLabel:
                        function (label) { return '&euro;' + label.value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","); },
                }
            ]
        };

        return (
            <div>
                <div className="container pd-x-0 pd-lg-x-10 pd-xl-x-0">
                    <div className="d-sm-flex align-items-center justify-content-between mg-b-20 mg-lg-b-30">
                        <div>
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb breadcrumb-style1 mg-b-10">
                                    <li className="breadcrumb-item"><Link to="/">Dashboard</Link></li>
                                    <li className="breadcrumb-item"><Link to="/finances">Finances</Link></li>
                                    <li className="breadcrumb-item active" aria-current="page">Product {name || ''}</li>
                                </ol>
                            </nav>
                            <h4 className="mg-b-0 tx-spacing--1">Product Chart for {name}.</h4>
                        </div>

                        <div className="d-none d-md-block">
                            <button className="btn btn-sm pd-x-15 btn-white btn-uppercase"><RefreshCw className="wd-10 mg-r-5" /> Refresh</button>
                            <button className="btn btn-sm pd-x-15 btn-primary btn-uppercase mg-l-5"><Save className="wd-10 mg-r-5" /> Export</button>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-md-12">
                            <Bar data={chartData} options={{
                                hover: { intersect: false }, scales: {
                                    yAxes: [{
                                        stacked: true,
                                        ticks: {
                                            beginAtZero: true,
                                            callback: function (value, index, values) {
                                                return '€' + value.toFixed().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                                            }
                                        }
                                    }],
                                    xAxes: [{ stacked: true }]
                                }
                            }} />
                        </div>
                    </div>
                </div>
            </div>
        );
    }

}

export default connect(state => ({ account: state.authentication.account, statistics: state.statistics, finances: state.finances }))(ProductFinances);
