import React from "react";
import { connect } from "react-redux";
import { navActions, reportsActions } from "./_actions";
import { Link } from "react-router-dom";
import {
    Plus,
    RefreshCw,
    Home
} from "react-feather";
import { Route, Switch } from "react-router";
import { ReportOverview } from "./ReportOverview";

class ReportsView extends React.Component {

    constructor(props) {
        super(props);

        this.props.dispatch(navActions.setTitle('Reports'));
    }

    render() {
        const { dispatch, openReports } = this.props;
        const accountId = this.props.account.account_id;

        return (
            <div className="container pd-x-0 tx-13">
                <div className="d-sm-flex align-items-center justify-content-between mg-b-20 mg-lg-b-30">
                    <div>
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb breadcrumb-style1 mg-b-10">
                                <li className="breadcrumb-item"><Link to="/">Dashboard</Link></li>
                                <li className="breadcrumb-item active" aria-current="page">Player Reports</li>
                            </ol>
                        </nav>

                        <h4 className="mg-b-0 tx-spacing--1">Report Overview</h4>
                        <p className="tx-color-03 mg-b-25">There are {openReports} open reports.</p>
                    </div>

                    <div className="d-none d-md-block">
                        <button onClick={() => this.refresh()} className="btn btn-sm pd-x-15 btn-white btn-uppercase"><RefreshCw /> Refresh</button>
                        <button className="btn btn-sm pd-x-15 btn-primary btn-uppercase mg-l-5"><Plus /> Something</button>
                    </div>
                </div>

                <div className="row">
                    <div className="col-sm-12 col-md-12">
                        <Switch>
                            <Route exact path="/support/reports" component={() => <ReportOverview accountId={accountId} dispatch={dispatch} />} />
                            <Route exact path="/support/reports/type/macroing" component={() => <ReportOverview type={2} accountId={accountId} dispatch={dispatch} />} />
                        </Switch>
                    </div>
                </div>
            </div>
        );
    }

}

function mapStateToProps(state) {
    return { account: state.authentication.account, openReports: state.reports.count };
}

const connected = connect(mapStateToProps)(ReportsView);
export { connected as ReportsView };
