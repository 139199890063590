import { reportsConstants } from "../_constants";

const initialState = {
    reports: []
};

export function reports(state = initialState, action) {
    switch (action.type) {
        case reportsConstants.UPDATE_REPORTS_RESPONSE:
            return {
                ...state,

                reports: action.payload,
                // count: action.payload.count
            };
        case reportsConstants.UPDATE_ASSIGNEE_RESPONSE: {
            let newState = { ...state };

            // Find and update relevant ticket
            let uid = action.payload.uid;
            newState.reports.forEach((v, i, ar) => {
                if (v && v.uid === uid) {
                    // New object to ensure rerendering
                    newState.reports[i] = { ...v, assignee: action.payload.assignee };
                }
            });

            return newState;
        }
        case reportsConstants.UPDATE_CHATLOG_RESPONSE: {
            let newState = { ...state };

            // Find and update relevant ticket
            let uid = action.payload.uid;
            newState.reports.forEach((v, i, ar) => {
                if (v && v.uid === uid) {
                    // New object to ensure rerendering
                    newState.reports[i] = { ...v, chatlog: action.payload.chatlog };
                }
            });

            return newState;
        }
        default:
            return state;
    }
}
