import { apiServices } from "./api.services";

export const financesServices = {
    updateFinances, updateFTB
};

function updateFinances() {
    return apiServices.axios.get('/finances.json').then(response => response.data)
}

function updateFTB() {
    return apiServices.axios.get('/finances/virginity.json').then(response => response.data)
}
