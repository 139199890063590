import React from "react";
import Humanize from 'humanize';
import { ArrowLeft, ArrowRight } from "react-feather";

class TopCosmetics extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            key: 'topCosmetics31d',
            sortBy: 'total_shards',
            offset: 0
        };

        this.switch = this.switch.bind(this);
    }

    switch(key) {
        this.setState({ key });
    }

    sortBy(sortBy) {
        this.setState({ sortBy });
    }

    render() {
        const { finances } = this.props;
        const { key, sortBy } = this.state;
        const data = finances[key];

        data && data.sort((one, two) => two[sortBy] - one[sortBy]);
        const totalShards = data ? data.reduce((prev, cur) => prev + cur.total_shards, 0) : 1;

        return (
            <div className="card">
                <div className="card-header d-sm-flex align-items-start justify-content-between">
                    <h6 className="lh-5 mg-b-0 flex-auto">Top Cosmetics</h6>

                    <div className="row flex-grow">
                        <div className="col-md-6 p-0 pr-1">
                            <select value={key} className="form-control form-control-sm flex-1 finance-selector" onChange={(e) => this.setState({ key: e.target.value })}>
                                <option value="topCosmetics">Overall</option>
                                <option value="topCosmetics31d">Last 31 days</option>
                                <option value="topCosmetics24h">Last 24 hours</option>
                            </select>
                        </div>

                        <div className="col-md-6 p-0">
                            <select value={sortBy} className="form-control form-control-sm flex-1 finance-selector" onChange={(e) => this.setState({ sortBy: e.target.value })}>
                                <option value="total_shards">By revenue</option>
                                <option value="sales">By sales</option>
                            </select>
                        </div>
                    </div>
                </div>

                <div className="card-body pd-y-15 pd-x-10">
                    <div className="table-responsive table-striped" style={{ maxHeight: 320 }}>
                        <table className="table table-borderless table-sm tx-13 tx-nowrap mg-b-0">
                            <thead>
                                <tr className="tx-10 tx-spacing-1 tx-color-03 tx-uppercase">
                                    <th></th>
                                    <th>Name</th>
                                    <th>Sales</th>
                                    <th>Total Shards</th>
                                    <th>Profit</th>
                                    <th>%</th>
                                </tr>
                            </thead>

                            <tbody>
                                {data && data.map(c => <tr key={c.type}>
                                    <td>#{data.indexOf(c) + 1}</td>
                                    <td><b>{c.name}</b></td>
                                    <td>{c.sales}</td>
                                    <td>{Humanize.numberFormat(c.total_shards, 0)}</td>
                                    <td>&euro; {Humanize.numberFormat(c.profit, 0, '.', ',')}</td>
                                    <td>{Humanize.numberFormat(c.total_shards / totalShards * 100, 2, '.', ',')}%</td>
                                </tr>)}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        );
    }

}

export default TopCosmetics;
