import React from "react";
import { connect } from "react-redux";
import { navActions, statisticsActions, financesActions } from "_actions";
import { Switch, Route } from 'react-router-dom';

import ProductRoute from './ProductFinances';
import TopCosmetics from './TopCosmetics';
import TopProducts from './TopProducts';
import TopBoosters from './TopBoosters';
import LatestFraud from "./LatestFraud";
import LatestBoosts from "./LatestBoosts";
import LatestTransactions from "./LatestTransactions";
import RevenueChart from "./RevenueChart";
import NewUserChart from "./NewUserChart";
import PageHeader from './PageHeader';
import FirstTimeBuyers from "./FirstTimeBuyers";

class FinancesRouter extends React.Component {

    constructor(props) {
        super(props);
        this.state = { timer: null };
    }

    componentDidMount() {
        this.props.dispatch(navActions.setTitle('Finances'));
        this.props.dispatch(financesActions.updateFinances());
        this.props.dispatch(statisticsActions.updateStats());

        // Add a recurring task to refresh financial data. Updates every 30 seconds.
        const updateTimerId = window.setInterval(() => {
            this.props.dispatch(financesActions.updateFinances());
            this.props.dispatch(statisticsActions.updateStats());
        }, 30000);

        this.setState({ timer: updateTimerId });
    }

    componentWillUnmount() {
        if (this.state.timer) {
            window.clearInterval(this.state.timer);
        }
    }

    render() {
        return (
            <Switch>
                <Route path="/finances/products/:id/:generic?" component={ProductRoute} />
                <Route exact path="/finances" component={FinancesRoute} />
            </Switch>
        );
    }

}


const Column = ({ children, wide, wideSizes = "col-lg-4 col-xl-3" }) => (
    <div className={(wide ? wideSizes : "") + " col-md-6 full-height mb-4"}>
        {children}
    </div>
)

class Finances extends React.Component {

    constructor(props) {
        super(props);

        this.state = { wide: localStorage.getItem('widemode') === "true" };
    }

    render() {
        const { finances, statistics } = this.props;
        const { wide } = this.state;

        return (
            <div>
                <div className="container pd-x-0 pd-lg-x-10 pd-xl-x-0" style={this.state.wide ? { maxWidth: '95%' } : {}}>
                    <PageHeader wide={this.state.wide} setWide={wide => { this.setState({ wide }); localStorage.setItem('widemode', wide + "") }} />

                    <div className="row align-center">
                        <Column wide={wide} wideSizes="">
                            <RevenueChart data={finances} />
                        </Column>

                        <Column wide={wide} wideSizes="">
                            <NewUserChart data={statistics} />
                        </Column>
                    </div>

                    <div className="row">
                        <Column wide={wide}>
                            <TopCosmetics finances={finances} />
                        </Column>

                        <Column wide={wide}>
                            <TopProducts finances={finances} />
                        </Column>

                        <Column wide={wide}>
                            <TopBoosters finances={finances} />
                        </Column>

                        <Column wide={wide}>
                            <LatestBoosts finances={finances} />
                        </Column>

                        <Column wide={wide}>
                            <LatestTransactions finances={finances} />
                        </Column>

                        <Column wide={wide}>
                            <LatestFraud finances={finances} />
                        </Column>

                        <Column wide={wide}>
                            <FirstTimeBuyers finances={finances} />
                        </Column>
                    </div>
                </div>
            </div>
        );
    }

}

const FinancesRoute = connect(state => ({ account: state.authentication.account, statistics: state.statistics, finances: state.finances }))(Finances);

function mapStateToProps(state) {
    return { account: state.authentication.account, statistics: state.statistics, finances: state.finances };
}

const connected = connect(mapStateToProps)(FinancesRouter);
export { connected as FinancesRouter };
