
import { authenticationConstants } from "../_constants";

// Grab the session token that's stored in the browser. If it's not valid, any call made will eventually
// make the app redirect to the login page and clear the session.
let token = localStorage.getItem('session-token');

const initialState = {
    token,
    account: token ? JSON.parse(atob(token.split('.')[1])) : undefined,
    loggingIn: false
};

// Ensure permissions are set
if (initialState.account && !initialState.account.permissions) {
    initialState.account.permissions = [];
}

export function authentication(state = initialState, action) {
    switch (action.type) {
        case authenticationConstants.ERROR:
            return {
                ...state,
                error: action.error,
                loggingIn: false
            };
        case authenticationConstants.CLEAR_ERROR:
            return {
                ...state,
                error: undefined,
                loggingIn: false
            };
        case authenticationConstants.LOGGING_IN:
            return {
                ...state,
                loggingIn: true
            };
        case authenticationConstants.LOGGED_IN:
            // Store the token in the local storage so that we can authenticate again next run.
            localStorage.setItem('session-token', action.token);
            console.log(action.token);

            const next = {
                ...state,
                token: action.token,
                account: JSON.parse(atob(action.token.split('.')[1])),
                loggingIn: false,
                error: undefined
            };

            // Ensure permissions are set
            if (next.account && !next.account.permissions) {
                next.account.permissions = [];
            }

            return next;
        case authenticationConstants.LOGOUT:
            return {
                ...state,
                token: undefined,
                loggingIn: false
            };
        default:
            return state;
    }
}
