import {staffConstants} from "../_constants";

const initialState = {
    list: []
};

export function staff(state = initialState, action) {
    switch (action.type) {
        case staffConstants.UPDATE_STAFF_LIST_RESPONSE:
            return {
                ...state,
                ...action.payload
            };
        default:
            return state;
    }
}
