import { apiServices } from "./api.services";

let items = {};
let cosmetics = {};

// Fetch item information on load
apiServices.axios.get('/data/items.json').then(resp => {
    items = resp.data;
})

apiServices.axios.get('/data/cosmetics.json').then(resp => {
    cosmetics = resp.data;
})

function getItems() {
    return items;
}

function getCosmetics() {
    return cosmetics;
}

export default {
    getItems, getCosmetics
};
