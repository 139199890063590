import {navConstants} from "../_constants";


export const navActions = {
    setTitle
};

function setTitle(title) {
    return {type: navConstants.SET_TITLE, payload: title};
}
