import React from "react";
import { connect } from "react-redux";
import ReactTimeago from "react-timeago";

class OnlineBadge extends React.Component {

    render() {
        const online = this.props.players.find(pl => pl.id === this.props.id);
        const lastOnline = this.props.lastOnline;

        if (online) {
            return <span className="badge badge-success">World {online.world}</span>
        } else if (lastOnline) {
            return <span className="badge badge-dark"><ReactTimeago date={lastOnline} /></span>
        }

        return null;
    }

}

function mapStateToProps(state) {
    return { players: state.statistics.players };
}

const connected = connect(mapStateToProps)(OnlineBadge);
export { connected as OnlineBadge };
