import {statisticsServices} from "../_services";
import {statisticsConstants} from "../_constants";


export const statisticsActions = {
    updatePlayers,
    updateCharts,
    updateStats
};

function updatePlayers() {
    return dispatch => {
        statisticsServices.updatePlayers().then(data => {
            dispatch({type: statisticsConstants.UPDATE_PLAYER_COUNTS_RESPONSE, payload: data})
        }).catch(reason => console.log("Error updating players: " + reason));
    };
}

function updateCharts() {
    return dispatch => {
        statisticsServices.updateCharts().then(data => {
            dispatch({type: statisticsConstants.UPDATE_CHARTS_RESPONSE, payload: data})
        });
    };
}

function updateStats() {
    return dispatch => {
        statisticsServices.updateStats().then(d => dispatch({type: statisticsConstants.UPDATE_STATS_RESPONSE, payload: d}));
    }
}
