import React from 'react';
import {Router, Route} from 'react-router-dom';
import {connect} from 'react-redux';
import {Switch} from "react-router";

import {history} from './_helpers';
import {LoginPage} from './LoginPage';
import {AuthLayout} from "./AuthLayout";

import './css/dashforge.css';
import './css/dashforge.dashboard.css';
import './css/acp.css';
import {PrivateRoute} from "./_components";

class App extends React.Component {

  constructor(props) {
    super(props);

    const {dispatch} = this.props;

    history.listen((location, action) => {
      dispatch(() => {
      });
    });
  }

  render() {
    return (
        <Router history={history}>
          <Switch>
            <Route path="/login" component={LoginPage}/>
            <PrivateRoute path="/" component={AuthLayout}/>
          </Switch>
        </Router>
    );
  }
}

function mapStateToProps(state) {
  return {};
}

const connected = connect(mapStateToProps)(App);
export default connected;
