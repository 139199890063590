import React from "react";
import { connect } from "react-redux";
import { history } from './_helpers';
import { Router, Route, Switch } from "react-router";
import { Link } from "react-router-dom";
import { Dashboard } from "./Dashboard";
import { Analytics } from "./Analytics";
import { FinancesRouter } from "./finances";
import { CharacterView } from "./CharacterView";
import { ReportsView } from "./ReportsView";
import { PatreonManager } from "./PatreonManager";
import { RtscManager } from "./RtscManager";
import { AssetManagement } from "./AssetManagement";
import { BotWatch } from "./BotWatch";
import { Support } from "./Support";
import { staffActions, statisticsActions } from "./_actions";
import {
    Menu,
    X,
    MessageSquare,
    Bell,
    LogOut,
    ChevronDown,
    Edit,
    User,
    Settings,
    HelpCircle,
    ShoppingBag,
    Crosshair,
    Search as SearchIcon,
    Grid,
    AlignLeft,
    Package,
    Tool,
    Shield,
    LifeBuoy, BookOpen, Home, Code, BarChart
} from "react-feather";
import { Search } from "./Search";
import { SearchDeviceId } from "./SearchDeviceId";
import { SearchIP } from "./SearchIP";
import Humanize from "humanize";
import { Guardians } from "Guardians";

class AuthLayout extends React.Component {

    constructor(props) {
        super(props);

        // Bind local functiosn
        this.updateStatistics = this.updateStatistics.bind(this);
        this.onSearchTyped = this.onSearchTyped.bind(this);
        this.doSearch = this.doSearch.bind(this);

        // Start handling the player count update here because this will be in view most of the time.
        const updateTimerId = window.setInterval(() => {
            this.updateStatistics();
        }, 10000);

        // Update right away too, so that we get current data.
        this.updateStatistics();

        this.state = {
            updateTimerId,
            searchTerm: ''
        };
    }

    componentWillUnmount() {
        // Remove the timer because we're no longer going to need that data.
        window.clearInterval(this.state.updateTimerId);
    }

    updateStatistics() {
        this.props.dispatch(statisticsActions.updatePlayers());
    }

    onSearchTyped(e) {
        const term = e.target.value;

        this.setState({ searchTerm: term });

        if (!term || !term.length) {
            history.push('/');
        }
    }

    doSearch(e) {
        e.preventDefault();

        const { searchTerm } = this.state;
        if (!searchTerm || !searchTerm.length) {
            history.push('/');
        } else {
            history.push('/search?q=' + encodeURIComponent(searchTerm));
        }

        return false;
    }

    render() {
        const { account, playerCount } = this.props;

        return (
            <div>
                <aside className="aside aside-fixed">
                    <div className="aside-header">
                        <Link to="/" className="aside-logo"><span>Curse of Aros</span></Link>
                        <Link to="/" className="aside-menu-link">
                            <Menu />
                            <X />
                        </Link>
                    </div>

                    <div className="aside-body">
                        <div className="aside-loggedin">
                            {/*<div className="d-flex align-items-center justify-content-start">
                                <a href="/" className="avatar"><img src="../../assets/img/img24.jpg" className="rounded-circle" /></a>
                                <div className="aside-alert-link">
                                    <a href="/" className="new" data-toggle="tooltip"
                                        title="You have 2 unread messages"><MessageSquare /></a>
                                    <a href="/" className="new" data-toggle="tooltip"
                                        title="You have 4 new notifications"><Bell /></a>
                                    <a href="/" data-toggle="tooltip" title="Sign out"><LogOut /></a>
                                </div>
                            </div>*/}

                            <div className="aside-loggedin-user">
                                <Link to="/" className="d-flex align-items-center justify-content-between mg-b-2" >
                                    <h6 className="tx-semibold mg-b-0">{account.username}</h6>
                                    <ChevronDown />
                                </Link>
                                <p className="tx-color-03 tx-12 mg-b-0">Bitgate, Inc.</p>
                            </div>

                            <div className="collapse" id="loggedinMenu">
                                <ul className="nav nav-aside mg-b-0">
                                    <li className="nav-item"><a href="#" className="nav-link"><Edit />
                                        <span>Edit Profile</span></a></li>
                                    <li className="nav-item"><a href="#" className="nav-link"><User />
                                        <span>View Profile</span></a></li>
                                    <li className="nav-item"><a href="#" className="nav-link"><Settings /> <span>Account Settings</span></a>
                                    </li>
                                    <li className="nav-item"><a href="#" className="nav-link"><HelpCircle /> <span>Help Center</span></a>
                                    </li>
                                    <li className="nav-item"><a href="#" className="nav-link"><LogOut />
                                        <span>Sign Out</span></a></li>
                                </ul>
                            </div>
                        </div>
                        {/* aside-loggedin */}

                        <ul className="nav nav-aside">
                            <li className="nav-label">Dashboard</li>
                            <li className="nav-item">
                                <Link to="/" className="nav-link"><Home /><span>Overview</span></Link>
                                <Link to="/analytics" className="nav-link"><BarChart /><span>Analytics</span></Link>
                            </li>

                            {account.account_id === 150 || account.account_id === 1155 || account.account_id === 1790670 ? <>
                                <li className="nav-label mg-t-25">Guardian Management</li>
                                <li className="nav-item">
                                    <Link to="/guardians" className="nav-link"><Shield /><span>Crew members</span></Link>
                                </li>
                            </> : null}

                            <li className="nav-label mg-t-25">Monitoring</li>
                            <li className="nav-item">
                                <Link to="/" className="nav-link"><MessageSquare /><span>Chat</span></Link>
                                <Link to="/botwatch" className="nav-link"><BookOpen /><span>BotWatch</span></Link>
                            </li>

                            <li className="nav-label mg-t-25">Customer Support</li>
                            <li className="nav-item">
                                <Link to="/support/reports" className="nav-link"><Crosshair /><span>Player Reports</span></Link>
                                <Link to="/support/issues" className="nav-link"><LifeBuoy /><span>Support Issues</span></Link>
                                <Link to="/support/bugs" className="nav-link"><Code /><span>Bug Reports</span></Link>
                            </li>

                            {account.account_id === 150 || account.account_id === 1721449 ? <>
                                <li className="nav-label mg-t-25">Game Developer</li>
                                <li className="nav-item">
                                    <Link to="/dev/rtsc" className="nav-link"><Tool /><span>Real-Time Config</span></Link>
                                    <Link to="/dev/assets" className="nav-link"><Package /><span>Game Assets</span></Link>
                                    <Link to="/support/reports" className="nav-link"><SearchIcon /><span>Staff Auditing</span></Link>
                                </li>
                            </> : null}

                        </ul>
                    </div>
                </aside>

                <div className="content ht-100v pd-0">
                    <div className="content-header">
                        <div className="content-search">
                            <SearchIcon />
                            <form onSubmit={this.doSearch}>
                                <input type="search" className="form-control" placeholder="Search..."
                                    onChange={this.onSearchTyped} />
                            </form>
                        </div>

                        <nav className="nav">
                            <a href="/" className="nav-link"><HelpCircle /></a>
                            <a href="/" className="nav-link"><Grid /></a>
                            <a href="/" className="nav-link"><AlignLeft /></a>

                            <PlayerOnlineCounter />
                        </nav>
                    </div>
                    {/* content-header */}

                    <div className="content-body">
                        <Switch>
                            <Route exact path="/" component={Dashboard} />
                            <Route exact path="/analytics" component={Analytics} />
                            <Route path="/finances" component={FinancesRouter} />
                            <Route exact path="/search" component={Search} />
                            <Route path="/search/device/:device" component={SearchDeviceId} />
                            <Route path="/search/ip/:ip" component={SearchIP} />
                            <Route path="/characters/:character" component={CharacterView} />

                            <Route path="/support/reports" component={ReportsView} />

                            <Route path="/patreon" component={PatreonManager} />
                            <Route path="/guardians" component={Guardians} />

                            <Route path="/botwatch" component={BotWatch} />
                            <Route path="/support/issues" component={Support} />
                            <Route path="/support/bugs" component={Support} />

                            <Route path="/dev/rtsc" component={RtscManager} />
                            <Route path="/dev/assets" component={AssetManagement} />
                        </Switch>
                    </div>
                </div>
                {/* content */}
            </div>
        );
    }

}

const PlayerOnlineCounter = connect(state => ({ count: state.statistics.players.length, yesterday: state.statistics.playersYesterday }))(props => {
    let sign = props.count >= props.yesterday ? "+" : "";
    let col = props.count >= props.yesterday ? "green" : "red";
    return (
        <Link to="/" className="btn btn-outline-primary btn-xs ml-4">Players Online: {Humanize.numberFormat(props.count, 0)}
            <span style={{ color: col }}> {sign}{props.count - props.yesterday}</span></Link>
    );
});

function mapStateToProps(state) {
    return { account: state.authentication.account };
}

const connected = connect(mapStateToProps)(AuthLayout);
export { connected as AuthLayout };
