import React from 'react';
import { connect } from 'react-redux';

import { authenticationActions } from './_actions';

import * as firebase from "firebase/app";
import "firebase/auth";
import * as firebaseui from 'firebaseui';

var firebaseConfig = {
    apiKey: "AIzaSyATK-24n7jWqMtghxb-HtogEZccJ8_NpWQ",
    authDomain: "curse-of-aros.firebaseapp.com",
    databaseURL: "https://curse-of-aros.firebaseio.com",
    projectId: "curse-of-aros",
    storageBucket: "curse-of-aros.appspot.com",
    messagingSenderId: "216084705424",
    appId: "1:216084705424:web:e514f00d9d8a04d3"
};

// Initialize Firebase
firebase.initializeApp(firebaseConfig);

// Initialize the FirebaseUI Widget using Firebase.
const ui = new firebaseui.auth.AuthUI(firebase.auth());
ui.disableAutoSignIn();

class LoginPage extends React.Component {

    uiConfig = {
        callbacks: {
            signInSuccess: (currentUser, credential, redirectUrl) => {
                currentUser.getIdToken().then(x => {
                    this.props.dispatch(authenticationActions.clearError());
                    this.props.dispatch(authenticationActions.login(x));
                });
            }
        },
        signInOptions: [
            {
                provider: firebase.auth.GoogleAuthProvider.PROVIDER_ID,
                customParameters: {
                    prompt: 'select_account'
                }
            },
            'apple.com',
        ],
        signInFlow: 'popup'
    };

    componentDidMount() {
        ui.start('#firebaseui-auth-container', this.uiConfig);
    }

    render() {
        const { error } = this.props;

        return (
            <div className="page">
                <div className="page-single">
                    <div className="container">
                        <div className="row">
                            <div className="col col-login mx-auto">
                                <div className="text-center mb-6">
                                    <img src="http://curseofaros.com/img/logo.png" alt="" />
                                </div>

                                <form className="card">
                                    <div className="card-body p-6">
                                        <p className="error">
                                            {error && this.renderError()}
                                        </p>

                                        <div id="firebaseui-auth-container"></div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    renderError() {
        if (!this.props.error) {
            return;
        }

        return (
            <div className="alert alert-danger alert-dismissible fade show" role="alert">
                <strong>Oops!</strong> Little bit error. :(
            </div>
        );
    };

}

function mapStateToProps(state) {
    return state.authentication;
}

const connected = connect(mapStateToProps)(LoginPage);
export { connected as LoginPage };

