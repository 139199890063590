import {reportsServices} from "../_services";
import {reportsConstants} from "../_constants";

export const reportsActions = {
    updateReports,
    assignToMe,
    unassign,
    close,
    loadChat
};

function updateReports(type) {
    return dispatch => {
        reportsServices.updateReports(type).then(data => {
            dispatch({type: reportsConstants.UPDATE_REPORTS_RESPONSE, payload: data})
        });
    };
}

function assignToMe(id) {
    return dispatch => {
        reportsServices.assignToMe(id).then(data => {
            dispatch({type: reportsConstants.UPDATE_ASSIGNEE_RESPONSE, payload: data})
        }).catch(e => alert("Someone has already taken this report."));
    }
}

function unassign(id) {
    return dispatch => {
        reportsServices.unassign(id).then(data => {
            dispatch({type: reportsConstants.UPDATE_ASSIGNEE_RESPONSE, payload: data})
        }).catch(e => alert("This was not assigned to you."));
    }
}

function close(id, closePrevious) {
    return dispatch => {
        reportsServices.close(id, closePrevious).then(data => {
            dispatch({type: reportsConstants.UPDATE_REPORTS_RESPONSE, payload: data})
        }).catch(e => alert("Cannot close report."));
    }
}

function loadChat(id) {
    return dispatch => {
        reportsServices.loadChat(id).then(data => {
            dispatch({type: reportsConstants.UPDATE_CHATLOG_RESPONSE, payload: data})
        }).catch(e => alert("Cannot load chat logs."));
    }
}
