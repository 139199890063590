import {apiServices} from "./api.services";

export const statisticsServices = {
    updatePlayers,
    updateCharts,
    updateStats
};

function updatePlayers() {
    return apiServices.axios.get('/players.json').then(response => response.data).then(data => {
        const {players} = data;

        players.forEach(p => {
            p.since = new Date(p.since);
        });
        
        return data;
    });
}

function updateCharts() {
    return apiServices.axios.get('/charts.json').then(response => response.data).then(charts => {
        return charts;
    });
}

function updateStats() {
    return apiServices.axios.get('/stats.json').then(r => r.data).then(c => {
        return c;
    });
}