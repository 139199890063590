import React from "react";
import { Link } from 'react-router-dom';


class LatestFraud extends React.Component {

    constructor(props) {
        super(props);
        this.state = { limit: 10 };
    }

    switch = () => { }

    render() {
        const { finances } = this.props;
        const { limit } = this.state;
        const data = finances.latestFruad;
        const canLoadMore = data && limit < data.length;

        return (
            <div className="card">
                <div className="card-header d-sm-flex align-items-start justify-content-between">
                    <h6 className="lh-5 mg-b-0">Latest Cunts</h6>
                </div>

                <div className="card-body pd-y-15 pd-x-10">
                    <div className="table-responsive table-striped">
                        <table className="table table-borderless table-sm tx-13 tx-nowrap mg-b-0">
                            <thead>
                                <tr className="tx-10 tx-spacing-1 tx-color-03 tx-uppercase">
                                    <th>Player</th>
                                    <th>Reason</th>
                                    <th>Product</th>
                                    <th>Amount</th>
                                    <th>Credited At</th>
                                    <th>Purchase Time</th>
                                    <th>Void Time</th>
                                    <th>Order Id</th>
                                </tr>
                            </thead>

                            <tbody>
                                {data && data.slice(0, Math.min(limit, data.length)).map(c => <tr key={c.voidedTime}>
                                    <td><Link to={`/characters/${c.characterId}`}><b>{c.name}</b></Link></td>
                                    <td>{mapCuntName(c.reason)}</td>
                                    <td>{c.product}</td>
                                    <td>&euro; {c.amount.substr(1)}</td>
                                    <td>{c.creditedAt}</td>
                                    <td>{c.purchaseTime}</td>
                                    <td>{c.voidedTime}</td>
                                    <td>{c.orderId}</td>
                                </tr>)}
                            </tbody>
                        </table>
                    </div>
                </div>

                <div className="card-footer">
                    <button className="btn btn-block btn-dark btn-xs" disabled={!canLoadMore} onClick={() => this.setState({limit: limit + 10})}>Load more</button>
                </div>
            </div>
        );
    }

}

const mapCuntName = (raw) => {
    switch(raw) {
        case 'FRIENDLY_FRAUD': return '"Friendly" Cunt';
        case 'REMORSE': return 'Cunt with Remorse';
        case 'ACCIDENTAL_PURCHASE': return '"Accidental" Cunt';
        default: return raw
    }
}

export default LatestFraud;
