import axios from 'axios';
import { store } from "../_helpers";
import { history } from "../_helpers";
import { authenticationActions } from "../_actions";

export const authenticationService = {
    login,
    logout
};

async function login(token) {
    return (await axios.post(process.env.CONFIG_API_BASE + '/auth/login', { token })).data;
}

function logout() {
    // Nuke token and redirect.
    store.dispatch(authenticationActions.logout());
    history.push('/');
}
