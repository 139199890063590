import { financesConstants } from "../_constants";

const initialState = {
    chart: [],
    ftb31: []
};

export function finances(state = initialState, action) {
    switch (action.type) {
        case financesConstants.UPDATE_FINANCES_RESPONSE:
            return {
                ...state,

                ...action.payload
            };
        default:
            return state;
    }
}