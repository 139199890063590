import React from "react";
import { Link } from 'react-router-dom';
import Humanize from 'humanize';


class TopProducts extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            key: 'topItems31d',
            sortBy: 'total_shards',
            limit: 100
        };

        this.switch = this.switch.bind(this);
    }

    switch(key) {
        this.setState({ key });
    }

    sortBy(sortBy) {
        this.setState({ sortBy });
    }

    render() {
        const { finances } = this.props;
        const { key, sortBy } = this.state;
        let data = finances[key];

        if (data) {
            data = JSON.parse(JSON.stringify(data));
        }

        // Rename some shit
        if (data && data.forEach) {
            data.forEach(e => {
                if (e.item === 523) {
                    e.name = 'Wishing Well';
                } else if (e.item === 557) {
                    e.name = 'Easter Event 2020';
                }
            })
        }

        data && data.sort((one, two) => two[sortBy] - one[sortBy]);
        data = data && data.filter(e => e.item !== 210 && e.item !== 209);

        const totalShards = data ? data.reduce((prev, cur) => prev + cur.total_shards, 0) : 1;

        return (
            <div className="card">
                <div className="card-header d-sm-flex align-items-start justify-content-between">
                    <h6 className="lh-5 mg-b-0 flex-1">Top Profiting Items</h6>

                    <select value={key} className="form-control form-control-sm flex-1 finance-selector" onChange={(e) => this.setState({ key: e.target.value })}>
                        <option value="topItems">Overall</option>
                        <option value="topItems31d">Last 31 days</option>
                        <option value="topItems24h">Last 24 hours</option>
                    </select>
                </div>

                <div className="card-body pd-y-15 pd-x-10">
                    <div className="table-responsive table-striped" style={{ maxHeight: 320 }}>
                        <table className="table table-borderless table-sm tx-13 tx-nowrap mg-b-0">
                            <thead>
                                <tr className="tx-10 tx-spacing-1 tx-color-03 tx-uppercase">
                                    <th></th>
                                    <th>Name</th>
                                    <th>Sales</th>
                                    <th>Total Shards</th>
                                    <th>Profit</th>
                                    <th>%</th>
                                </tr>
                            </thead>

                            <tbody>
                                {data && data.map(c => <tr key={c.item}>
                                    <td>#{data.indexOf(c) + 1}</td>
                                    <td><Link to={(c.product_id !== undefined ? `/finances/products/${c.product_id}/true` : `/finances/products/${c.item}`)}><b>{c.name}</b></Link></td>
                                    <td>{Humanize.numberFormat(c.sales, 0)}</td>
                                    <td>{Humanize.numberFormat(c.total_shards, 0)}</td>
                                    <td>&euro; {Humanize.numberFormat(c.profit.toFixed(), 0, '.', ',')}</td>
                                    <td>{Humanize.numberFormat(c.total_shards / totalShards * 100, 2, '.', ',')}%</td>
                                </tr>)}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        );
    }

}

export default TopProducts;
