import React from 'react';
import {connect} from "react-redux";
import {Link} from "react-router-dom";
import {Home, Plus, RefreshCw} from "react-feather";
import {Route, Switch} from "react-router";
import {apiServices} from "./_services";
import {PatronList} from "./PatronList";

class PatreonManager extends React.Component {


    constructor(props) {
        super(props);

        this.state = {
            patrons: [],
            activeOnly: false
        };

        apiServices.axios.get('/patreon/patrons').then(r => {
            this.setState({patrons: r.data});
        })
    }

    render() {
        const {dispatch} = this.props;
        const {patrons, activeOnly} = this.state;

        return (
            <div className="container-fluid pd-x-0 tx-13">
                <div className="d-sm-flex align-items-center justify-content-between mg-b-20 mg-lg-b-30">
                    <div>
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb breadcrumb-style1 mg-b-10">
                                <li className="breadcrumb-item"><Link to="/">Dashboard</Link></li>
                                <li className="breadcrumb-item active" aria-current="page">Patreon</li>
                            </ol>
                        </nav>

                        <h4 className="mg-b-0 tx-spacing--1">Patreon Management</h4>
                        <p className="tx-color-03 mg-b-25">There are { patrons.length } patrons.</p>
                    </div>

                    <div className="d-none d-md-block">
                        <button onClick={() => apiServices.axios.post('/patreon/sync')} className="btn btn-sm pd-x-15 btn-white btn-uppercase"><RefreshCw /> Update Patron List</button>
                        <button onClick={() => apiServices.axios.post('/patreon/syncranks')} className="btn btn-sm pd-x-15 btn-white btn-uppercase"><RefreshCw /> Prune Freeloaders</button>
                        <button className="btn btn-sm pd-x-15 btn-primary btn-uppercase mg-l-5"><Plus /> Something</button>
                    </div>
                </div>

                <div className="row">
                    <div className="col-sm-3 col-md-3 col-xl-1 card">
                        <div className="row">
                            <div className="card-body">
                                <label className="tx-sans tx-10 tx-semibold tx-uppercase tx-color-01 tx-spacing-1 mg-b-15">Navigation</label>

                                <nav className="nav nav-classic tx-13">
                                    <Link to={`/patreon`} className="nav-link"><Home /> Patron List</Link>
                                    <Link to={`/support/reports/type/macroing`} className="nav-link"><Home /> Actions</Link>
                                </nav>
                            </div>
                        </div>
                    </div>

                    <div className="col-sm-9 col-md-9 col-xl-11">
                        <Switch>
                            <Route exact path="/patreon" component={() => <PatronList patrons={patrons} activeOnly={activeOnly} dispatch={dispatch}/>} />
                        </Switch>
                    </div>
                </div>
            </div>
        );
    }

}


function mapStateToProps(state) {
    return {};
}

const connected = connect(mapStateToProps)(PatreonManager);
export {connected as PatreonManager};
