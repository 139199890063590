import axios from 'axios';
import { store } from "../_helpers";
import { authenticationService } from "./authentication.services";

const axiosInstance = axios.create({
    baseURL: process.env.CONFIG_API_BASE
});

axiosInstance.interceptors.request.use(axiosIntercept);
axiosInstance.interceptors.response.use(axiosResponseIntercept, axiosErrorIntercept);

function axiosIntercept(value) {
    const {authentication: authState } = store.getState();

    // If we have a valid logged-in state and a token, put the token in the request.
    if (authState && authState.token) {
        value.headers.authorization = 'Bearer ' + authState.token;
    }

    return value;
}

function axiosResponseIntercept(value) {
    // Optional interception checks here... such as banned or demoted user. Stuff like that.
    return value;
}

function axiosErrorIntercept(error) {
    console.log(JSON.stringify(error));
    
    // When 401 unauthorized, redirect the user to the login page.
    if (error && error.response && error.response.status === 401) {
        authenticationService.logout()
        return Promise.reject(error);
    }
}

function currentToken() {
    const {authentication: authState } = store.getState();

    return authState.token;
}

export const apiServices = {
    axios: axiosInstance,
    currentToken
};
