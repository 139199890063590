import React from 'react';
import {connect} from "react-redux";
import {Link} from "react-router-dom";
import {RefreshCw, Users, LifeBuoy, Cpu} from "react-feather";
import {Route, Switch} from "react-router";
import {apiServices} from "./_services";

class Support extends React.Component {


    constructor(props) {
        super(props);

        this.state = {
            issues: [],
            bugs: []
        };

        this.refresh = this.refresh.bind(this);
        this.refresh();
    }

    refresh() {
        this.setState({issues: [], bugs: []});

        apiServices.axios.get('/support/issues.json').then(r => {
            this.setState({issues: r.data});
        })

        apiServices.axios.get('/support/bugs.json').then(r => {
            this.setState({bugs: r.data});
        })
    }

    render() {
        const {issues, bugs} = this.state;

        return (
            <div className="container-fluid pd-x-0 tx-13">
                <div className="d-sm-flex align-items-center justify-content-between mg-b-20 mg-lg-b-30">
                    <div>
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb breadcrumb-style1 mg-b-10">
                                <li className="breadcrumb-item"><Link to="/">Dashboard</Link></li>
                                <li className="breadcrumb-item active" aria-current="page">Support</li>
                            </ol>
                        </nav>

                        <h4 className="mg-b-0 tx-spacing--1">Support Systems</h4>
                        <p className="tx-color-03 mg-b-25">Support issues and bug reports.</p>
                    </div>

                    <div className="d-none d-md-block">
                        <button onClick={this.refresh} className="btn btn-sm pd-x-15 btn-white btn-uppercase"><RefreshCw /> Refresh</button>
                    </div>
                </div>

                <div className="row">
                    <div className="col-sm-3 col-md-3 col-xl-2 card">
                        <div className="row">
                            <div className="card-body">
                                <label className="tx-sans tx-10 tx-semibold tx-uppercase tx-color-01 tx-spacing-1 mg-b-15">Navigation</label>

                                <nav className="nav nav-classic tx-13">
                                    <Link to={`/support/issues`} className="nav-link"><Users /> Support Issues</Link>
                                    <Link to={`/support/bugs`} className="nav-link"><Cpu /> Bug Reports</Link>
                                </nav>
                            </div>
                        </div>
                    </div>

                    <div className="col-sm-9 col-md-9 col-xl-10">
                        <Switch>
                            <Route exact path="/support/issues" component={() => <Issues issues={issues}/>} />
                            <Route exact path="/support/bugs" component={() => <Bugs bugs={bugs}/>} />
                        </Switch>
                    </div>
                </div>
            </div>
        );
    }

}

function Issues(props) {
    const {issues} = props;
    const normalized = issues.map(i => <IssueCard issue={i} />);
    return (<div className="row">{normalized}</div> );
}

function Bugs(props) {
    const {bugs} = props;
    const normalized = bugs.map(i => <BugCard bug={i} />);
    return (<div className="row">{normalized}</div> );
}

function IssueCard(props) {
    const {issue} = props;

    return (
        <div className="col-md-6">
            <div className="card mb-2">
                <div className="card-header d-sm-flex align-items-start justify-content-between">
                    <h6 className="lh-5 mg-b-0"><LifeBuoy/> #{issue.id} {issue.category} - {issue.email}</h6>
                    <span className="text-muted">{issue.reported_at}</span>
                </div>

                <div className="card-body pd-y-15 pd-x-10">
                    <b>Username:</b> {issue.username ? <i><Link to={`/search?q=${issue.username}`}>{issue.username}</Link></i> : <i>Not specified</i>}<br/>
                    <b>Email:</b> {issue.email ? <i>{issue.email}</i> : <i>Not specified</i>}<br/>
                    <b>Reported:</b> {issue.reported_at}<br/>
                    <b>IP:</b> <Link to={`/search/ip/${issue.ip}`}>{issue.ip}</Link><br/>
                    <b>Device:</b> {issue.platform} {issue.device} {issue.extra_device ? ` (${issue.extra_device})` : ''}<br/> 

                    <hr />

                    <div className="table-responsive">
                        <p><i>"{issue.description}"</i></p>
                    </div>
                </div>
            </div>
        </div>
    );
}

function BugCard(props) {
    const {bug} = props;

    return (
        <div className="col-md-6">
            <div className="card mb-2">
                <div className="card-header d-sm-flex align-items-start justify-content-between">
                    <h6 className="lh-5 mg-b-0"><Cpu /> #{bug.id} {bug.category} - {bug.email}</h6>
                    <span className="text-muted">{bug.reported_at}</span>
                </div>

                <div className="card-body pd-y-15 pd-x-10">
                    <b>Username:</b> {bug.username ? <i><Link to={`/search?q=${bug.username}`}>{bug.username}</Link></i> : <i>Not specified</i>}<br/>
                    <b>Email:</b> {bug.email ? <i>{bug.email}</i> : <i>Not specified</i>}<br/>
                    <b>Reported:</b> {bug.reported_at}<br/>
                    <b>IP:</b> <Link to={`/search/ip/${bug.ip}`}>{bug.ip}</Link><br/>
                    <b>Device:</b> {bug.platform} {bug.device} {bug.extra_device ? ` (${bug.extra_device})` : ''}<br/> 

                    <hr />

                    <div className="table-responsive">
                        <p><b>What were you doing?</b><br/><i>"{bug.doing_what}"</i></p>
                        
                        <p><b>What happened?</b><br/><i>"{bug.what}"</i></p>
                    </div>
                </div>
            </div>
        </div>
    );
}

function mapStateToProps(state) {
    return {};
}

const connected = connect(mapStateToProps)(Support);
export {connected as Support};