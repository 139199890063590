import { apiServices } from "./api.services";

export const reportsServices = {
    updateReports,
    assignToMe,
    unassign,
    close,
    loadChat
};

function updateReports(reason) {
    return apiServices.axios.get(reason ? ('/guardians/reports?reason=' + reason) : '/guardians/reports').then(response => response.data);
}

function assignToMe(id) {
    return apiServices.axios.post('/reports/' + id + '/selfassign').then(response => response.data);
}

function unassign(id) {
    return apiServices.axios.post('/reports/' + id + '/unassign').then(response => response.data);
}

function close(id, closePrevious) {
    return apiServices.axios.post('/reports/' + id + '/close', { close_previous: closePrevious }).then(response => response.data);
}

function loadChat(id) {
    return apiServices.axios.get('/reports/' + id + '/chatlog').then(response => response.data);
}
