import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { RefreshCw } from 'react-feather';
import { apiServices } from './_services';
import queryString from 'query-string';
import levenshtein from 'js-levenshtein';
import { xpToLevel } from './_helpers';
import { OnlineBadge } from "./OnlineBadge";

class Search extends React.Component {

    constructor(props) {
        super(props);

        const query = queryString.parse(this.props.location.search).q;
        this.state = {
            query,
            characters: [],
            nameHistory: [],
            character: null,
            loaded: false,
        };

        this.search = this.search.bind(this);
        this.search(query);
    }

    shouldComponentUpdate(props, state) {
        return this.props !== props || this.state !== state;
    }

    componentDidUpdate() {
        const query = queryString.parse(this.props.location.search).q;
        if (query !== this.state.query) {
            this.search(query);
        }
    }

    search(query) {
        this.setState({ 
            query,
            loaded: false
         });

        apiServices.axios.get('/search/characters.json?q=' + encodeURIComponent(query)).then(resp => {
            const direct = resp.data.find((v) => v.name.toLowerCase() === query.toLowerCase());

            // Sort using levenshtein distance
            const characters = resp.data;
            characters.sort((a, b) => levenshtein(a.name.toLowerCase(), query.toLowerCase()) - levenshtein(b.name.toLowerCase(), query.toLowerCase()));

            this.setState({ 
                character: direct, 
                characters: resp.data,
                loaded: true 
            });
        });

        apiServices.axios.get('/search/namehist.json?q=' + encodeURIComponent(query)).then(resp => {
            this.setState({ nameHistory: resp.data });
        })
    }

    render() {
        const { characters } = this.state;
        const { nameHistory } = this.state;
        const { loaded } = this.state;

        return (
            <div className="container pd-x-0 tx-13">
                <div className="d-sm-flex align-items-center justify-content-between mg-b-20 mg-lg-b-30">
                    <div>
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb breadcrumb-style1 mg-b-10">
                                <li className="breadcrumb-item"><Link to="/">Dashboard</Link></li>
                                <li className="breadcrumb-item active" aria-current="page">Search</li>
                            </ol>
                        </nav>

                        <h4 className="mg-b-0 tx-spacing--1">Search results</h4>
                    </div>

                    <div className="d-none d-md-block">
                        <button className="btn btn-sm pd-x-15 btn-white btn-uppercase"
                            onClick={() => this.search(this.state.query)}><RefreshCw /> Refresh
                        </button>
                    </div>
                </div>

                {loaded && characters && characters.length ? <CharacterResults characters={characters} /> : loaded ? <h4>No results found for {this.state.query}</h4> : <Spinner />}
                {loaded && nameHistory && nameHistory.length ? <NameHistory entries={nameHistory} /> : loaded ? <h6>No name history found for {this.state.query}</h6> : <Spinner />}
            </div>
        );
    }

}

const CharacterResults = (props) => (
    <div className="card">
        <div className="card-header d-sm-flex align-items-start justify-content-between">
            <h6 className="lh-5 mg-b-0">Characters</h6>
        </div>

        <div className="card-body pd-y-15 pd-x-10">
            <div className="table-responsive">
                <table className="table table-borderless table-sm tx-13 tx-nowrap mg-b-0">
                    <thead>
                        <tr className="tx-10 tx-spacing-1 tx-color-03 tx-uppercase">
                            <th>Name</th>
                            <th>Level</th>
                            <th>Email</th>
                            <th>Last online</th>
                            <th>Registered</th>
                        </tr>
                    </thead>
                    <tbody>
                        {props.characters.map(player =>
                            <tr key={player.id}>
                                <td><Link to={`/characters/${player.id}`}>{player.name}</Link> <OnlineBadge id={player.id} /> {player.muted &&
                                    <span className="badge badge-warning ml-2">Muted</span>} {player.banned &&
                                        <span className="badge badge-danger ml-2">Banned</span>}</td>
                                <td>{xpToLevel(player.xp)}</td>
                                <td>{player.email}</td>
                                <td>{player.last_online}</td>
                                <td>{player.created_at}</td>
                            </tr>
                        )}
                    </tbody>
                </table>
            </div>
        </div>
    </div>
);

const NameHistory = (props) => (
    <div className="card">
        <div className="card-header d-sm-flex align-items-start justify-content-between">
            <h6 className="lh-5 mg-b-0">Name changes</h6>
        </div>

        <div className="card-body pd-y-15 pd-x-10">
            <div className="table-responsive">
                <table className="table table-borderless table-sm tx-13 tx-nowrap mg-b-0">
                    <thead>
                        <tr className="tx-10 tx-spacing-1 tx-color-03 tx-uppercase">
                            <th>Old name</th>
                            <th>Changed to</th>
                            <th>Current name</th>
                            <th>Time</th>
                        </tr>
                    </thead>
                    <tbody>
                        {props.entries.map(player =>
                            <tr key={player.character_id}>
                                <td>{player.old_name}</td>
                                <td>{player.new_name}</td>
                                <td><Link to={`/characters/${player.character_id}`}>{player.current_name}</Link> <OnlineBadge id={player.character_id} /></td>
                                <td>{player.time}</td>
                            </tr>
                        )}
                    </tbody>
                </table>
            </div>
        </div>
    </div>
);

const Spinner = (props) => (<div className="text-center">
    <div className="spinner-border" />
</div>);

const mapStateToProps = (state) => {
    const { match } = state;
    return { match }
};

const connected = connect(mapStateToProps)(Search);
export { connected as Search };
