import React from "react";
import { Line } from 'react-chartjs-2';

class FirstTimeBuyers extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            chartKey: 'ftb31'
        };

        this.switch = this.switch.bind(this);
    }

    switch(key) {
        this.setState({ chartKey: key });
    }

    render() {
        const graph = this.props.finances[this.state.chartKey] || [];

        let labels = [];
        let data = [];
        let data2 = [];

        const options = { dateStyle: 'short' };

        graph.forEach(a => {
            
            labels.push(new Date(Date.parse(a.date_trunc)).toLocaleDateString(undefined, options));
            data.push(a.first_time_buyers);
            data2.push(a.peak_players);
        });

        console.log(graph);

        const chartData = {
            labels: labels,
            datasets: [
                {
                    yAxisID: 'A',
                    label: 'First-time Buyers',
                    backgroundColor: 'rgba(1, 104, 250, 0.2)',
                    borderColor: 'rgba(1, 104, 250)',
                    borderWidth: 1,
                    hoverBackgroundColor: 'rgba(1, 104, 250, 0.4)',
                    hoverBorderColor: 'rgba(1, 104, 250, 1)',
                    data: data
                }, 
                {
                    yAxisID: 'B',
                    label: 'Unique players',
                    backgroundColor: 'rgba(104, 1, 1, 0.2)',
                    borderColor: 'rgba(104, 1, 1)',
                    borderWidth: 1,
                    hoverBackgroundColor: 'rgba(104, 1, 1, 0.4)',
                    hoverBorderColor: 'rgba(104, 1, 1, 1)',
                    data: data2
                }
            ]
        };

        return (
            <div className="card">
                <div className="card-header d-sm-flex align-items-start justify-content-between">
                    <h6 className="lh-5 mg-b-0 flex-1">First-time Buyers</h6>

                    <select value={this.state.chartKey} className="form-control form-control-sm flex-1 finance-selector" onChange={(e) => this.switch(e.target.value)}>
                        <option value="ftb31">Last 24 hours</option>
                    </select>
                </div>

                <div className="card-body pd-y-15 pd-x-10">
                    <Line data={chartData} options={{
                        hover: { intersect: false }, scales: {
                            yAxes: [{
                                id: 'A',
                                type: 'linear',
                                position: 'left',
                              }, {
                                id: 'B',
                                type: 'linear',
                                position: 'right',
                            }],
                            xAxes: [{ stacked: true }]
                        }
                    }} />
                </div>
            </div>
        );
    }

}

export default FirstTimeBuyers;
