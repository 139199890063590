import {navConstants} from "../_constants";

const initialState = {
    title: 'Dashbored'
};

export function nav(state = initialState, action) {
    switch (action.type) {
        case navConstants.SET_TITLE:
            return {
                ...state,
                title: action.payload
            };
        default:
            return state;
    }
}
