import React from 'react';
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { Home, Plus } from "react-feather";
import { Route, Switch } from "react-router";
import { apiServices } from "./_services";

class RtscManager extends React.Component {


    constructor(props) {
        super(props);

        this.state = {
            config: []
        };

        apiServices.axios.get('/db/rtsc.json').then(r => {
            this.setState({ config: r.data });
            console.log(r.data);
        })
    }

    render() {
        const { config } = this.state;

        return (
            <div className="container-fluid pd-x-0 tx-13">
                <div className="d-sm-flex align-items-center justify-content-between mg-b-20 mg-lg-b-30">
                    <div>
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb breadcrumb-style1 mg-b-10">
                                <li className="breadcrumb-item"><Link to="/">Dashboard</Link></li>
                                <li className="breadcrumb-item active" aria-current="page">Real-Time Config</li>
                            </ol>
                        </nav>

                        <h4 className="mg-b-0 tx-spacing--1">Real-Time Config</h4>
                        <p className="tx-color-03 mg-b-25">Manage game server configuration in real-time.</p>
                    </div>

                    <div className="d-none d-md-block">
                        <button className="btn btn-sm pd-x-15 btn-primary btn-uppercase mg-l-5"><Plus /> Add entry</button>
                    </div>
                </div>

                <div className="row">
                    <div className="col-sm-3 col-md-3 col-xl-2 card">
                        <div className="row">
                            <div className="card-body">
                                <label className="tx-sans tx-10 tx-semibold tx-uppercase tx-color-01 tx-spacing-1 mg-b-15">Navigation</label>

                                <nav className="nav nav-classic tx-13">
                                    <Link to={`/dev/rtsc`} className="nav-link"><Home /> Config List</Link>
                                    <Link to={`/support/reports/type/macroing`} className="nav-link"><Home /> History</Link>
                                </nav>
                            </div>
                        </div>
                    </div>

                    <div className="col-sm-9 col-md-9 col-xl-10">
                        <Switch>
                            <Route exact path="/dev/rtsc" component={() => <RtscList values={config} />} />
                        </Switch>
                    </div>
                </div>
            </div>
        );
    }

}

const RtscList = ({ values }) => {
    return (
        <div>
            <div className="table-responsive">
                <table className="table table-striped table-borderlesss table-sm tx-13 tx-nowrap mg-b-0">
                    <thead>
                        <tr className="tx-10 tx-spacing-1 tx-color-03 tx-uppercase">
                            <th>Configuration Key</th>
                            <th>Value Type</th>
                            <th>Value</th>
                            <th>Last Editor</th>
                        </tr>
                    </thead>

                    <tbody>
                        {values.map(v => <tr key={v.key}>
                            <td><code>{v.key}</code></td>
                            <td><code>{v.valuetype}</code></td>
                            <td>
                                <code>{v.valuetype === 'string' ? v.v_str :
                                    v.valuetype === 'double' ? v.v_double :
                                        v.valuetype === 'int' ? v.v_long :
                                            v.valuetype === 'bool' ? (v.v_long !== 0 ? 'true' : 'false') : null}</code>
                            </td>
                            <td><b>{v.last_edited_by}</b></td>
                        </tr>)}
                    </tbody>
                </table>
            </div>
        </div>
    )
}


function mapStateToProps(state) {
    return {};
}

const connected = connect(mapStateToProps)(RtscManager);
export { connected as RtscManager };
