import React from "react";
import { reportsActions } from "./_actions";
import { Report } from "./Report";
import { connect } from "react-redux";


class ReportOverview extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            alertClosed: localStorage.getItem('reports.guardian_warn_closed') || false,
        };

        this.refresh();
        this.closeWarning = this.closeWarning.bind(this);
    }

    refresh() {
        this.props.dispatch(reportsActions.updateReports(this.props.type));
    }

    closeWarning() {
        localStorage.setItem('reports.guardian_warn_closed', true);
        this.setState({ alertClosed: true });
    }

    render() {
        const { reports, accountId, type } = this.props;

        return (
            <div>
                {!this.state.alertClosed && <div class="alert alert-warning" role="alert">
                    Due to the high volume in non-serious reports, we only display reports from <b class="alert-link">Guardians</b> here.

                    <button type="button" class="close" onClick={this.closeWarning}>
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>}

                {reports.map(report => <Report uid={report.uid} accountId={accountId} />)}

                <button className="btn btn-outline-warning btn-xs">Older</button>
            </div>
        );
    }

}

function mapStateToProps(state) {
    return { reports: state.reports.reports };
}

const connected = connect(mapStateToProps)(ReportOverview);
export { connected as ReportOverview };
